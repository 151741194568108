import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import objectPath from 'object-path';
import HMenuItem from './HMenuItem';
import * as builder from '../../../ducks/builder';
import { toAbsoluteUrl } from '../../..';
import KTMenu from '../../../_assets/js/menu';
import KTOffcanvas from '../../../_assets/js/offcanvas';
import { UserRoles } from '../../../../app/interfaces/user';
import { useDefineUserRole } from '../../../../app/hooks';
import { IAppState } from '../../../../app/store/rootDuck';
import * as routerHelpers from '../../../../app/router/RouterHelpers';

const offcanvasOptions = {
  overlay: true,
  baseClass: 'kt-header-menu-wrapper',
  closeBy: 'kt_header_menu_mobile_close_btn',
  toggleBy: {
    target: 'kt_header_mobile_toggler',
    state: 'kt-header-mobile__toolbar-toggler--active',
  },
};

class HMenu extends React.Component {
  offCanvasCommonRef = React.createRef();
  ktMenuCommonRef = React.createRef();

  get currentUrl() {
    return this.props.location.pathname.split(/[?#]/)[0];
  }

  componentDidMount() {
    // Canvas
    this.initOffCanvas();
    // Menu
    this.initKTMenu();
  }

  initOffCanvas = () => {
    new KTOffcanvas(this.offCanvasCommonRef.current, offcanvasOptions);
  };

  initKTMenu = () => {
    let menuOptions = {
      submenu: {
        desktop: 'dropdown',
        tablet: 'accordion',
        mobile: 'accordion',
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
      dropdown: {
        timeout: 50,
      },
    };

    let menuDesktopMode = 'accordion';
    if (this.ktMenuCommonRef.current.getAttribute('data-ktmenu-dropdown') === '1') {
      menuDesktopMode = 'dropdown';
    }

    if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object') {
      menuOptions.submenu.desktop = {
        default: menuDesktopMode,
      };
    }

    // eslint-disable-next-line no-undef
    new KTMenu(this.ktMenuCommonRef.current, menuOptions);
  };

  render() {
    const {
      disabledAsideSelfDisplay,
      ktMenuClasses,
      ulClasses,
      rootArrowEnabled,
      me,
      isAuthorized,
    } = this.props;

    function filterMenuItems(menuItems, isAuthorized) {
      // Если пользователь не авторизован (isAuthorized = false)
      if (!isAuthorized) {
        // Фильтруем корневые элементы меню, исключая те, у которых title === "SUBMENU.BLOG.ALL"
        menuItems = menuItems.filter(item => item.title !== 'SUBMENU.BLOG.ALL');

        // Фильтруем элементы в submenu, исключая те, у которых title === "COLLECTIONS"
        menuItems.forEach(item => {
          if (item.submenu) {
            item.submenu = item.submenu.filter(subItem => subItem.title !== 'COLLECTIONS');

            // Если в submenu остался только один элемент, удаляем его
            if (item.submenu.length === 1) {
              item.submenu = null;
            }
          }
        });
      }

      return menuItems;
    }

    const items = filterMenuItems(this.props.menuConfig?.aside?.items, isAuthorized);

    return (
      <>
        <button className='kt-header-menu-wrapper-close' id='kt_header_menu_mobile_close_btn'>
          <i className='la la-close' />
        </button>
        <div
          className='kt-header-menu-wrapper'
          id='kt_header_menu_wrapper'
          ref={this.offCanvasCommonRef}
        >
          <div className='kt-header-mobile__toolbar'>
            {/* <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button> */}
          </div>

          {/* {disabledAsideSelfDisplay && ( */}
          {/*   <div className='kt-header-logo'> */}
          {/*     <Link to={`/products/catalog`}> */}
          {/*       <p style={{ margin: 0, fontWeight: '400', fontSize: 25, color: '#441861' }}> */}
          {/*         Barelton */}
          {/*       </p> */}
          {/*       /!*  <img alt='logo' src={this.getHeaderLogo()} />*!/ */}
          {/*     </Link> */}
          {/*   </div> */}
          {/* )} */}

          <div
            id='kt_header_menu'
            className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
            ref={this?.ktMenuCommonRef}
          >
            <ul className={`kt-menu__nav ${ulClasses}`}>
              {items?.map((item, index) => {
                if (item.title === 'MENU.TENDERS') return;
                return (
                  <React.Fragment key={`hmenuList${index}`}>
                    {item.title && (
                      <HMenuItem
                        item={item}
                        currentUrl={this.currentUrl}
                        rootArrowEnabled={rootArrowEnabled}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  isAuthorized: store.auth.user != null,
  ktMenuClasses: builder.selectors.getClasses(store, {
    path: 'header_menu',
    toString: true,
  }),
  rootArrowEnabled: builder.selectors.getConfig(store, 'header.menu.self.root-arrow'),
  headerSelfSkin: builder.selectors.getConfig(store, 'header.self.skin'),
  ulClasses: builder.selectors.getClasses(store, {
    path: 'header_menu_nav',
    toString: true,
  }),
  disabledAsideSelfDisplay:
    objectPath.get(store.builder.layoutConfig, 'aside.self.display') === false,
  me: store.profile.me,
});

export default withRouter(connect(mapStateToProps)(HMenu));
