/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormatMessage } from '../../../../app/hooks';
import getMenuConfig from '../../../../app/router/MenuConfig';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../app/store/ducks/product-catalog.duck';
import {createStyles, makeStyles,useTheme, useMediaQuery} from "@material-ui/core";
 const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: 'relative',
      minWidth: 150,
      // height: '2.8rem',
      height: '50px',
      justifyContent: 'center',
      marginRight: '0.5rem',
      overflow: 'hidden',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0,
      minWidth: '150px',
      height: '50px',
      padding: '0 15px 0px 25px',
      transform: 'translateX(-10px) skewX(340deg)',
      [theme.breakpoints.down(505)]: {
        minWidth: '120px',
      },
      [theme.breakpoints.down(420)]: {
        minWidth: '95px',
      },
    },
    boxItemsMenu: {
      fontSize: '14px',
      fontWeight: 300,
      zIndex: 2,
      cursor: 'pointer',
      [theme.breakpoints.down("sm")]: {
        minWidth: '40px',
      },

    },
    boxMenu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0,
      minWidth: '150px',
      height: '50px',
      padding: '0 15px 0px 25px',
      transform: 'translateX(-10px) skewX(340deg)',
      [theme.breakpoints.down("md")]: {
        minWidth: '100px',
        padding: '0 10px 0px 10px',
        fontSize: 12,
        height: '50px',

      },
    },
  })
);
function BreadCrumbs(props) {
  const fm = useFormatMessage();
  const { items, hideBreadcrumbIcon, isSubmenu, userRoles, isAuthorized } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!items || !items.length) {
    return '';
  }
  const menu = getMenuConfig(userRoles, isAuthorized);
  const itemsMenu = useMemo(
    () =>
      menu?.aside?.items.find(item => {
        return item?.submenu?.some(elem => elem.page === items[0].page);
      }),
    [items, menu]
  );
  const length = items.length;

  return (
    <div
      style={{display: isSmallScreen && isSubmenu ? 'none' : "flex" }}
      className={`kt-subheader__breadcrumbs ${classes.container}`}
    >
      {isSubmenu
        ? itemsMenu?.submenu?.map((item, index) => (
            <Link
              className={`kt-subheader__breadcrumbs-link sub-menu ${classes.boxItemsMenu} ${
                history.location.pathname === `/${item.page}` ? 'active' : ''
              }`}
              to={`/${item.page}`}
              onClick={() => dispatch(actions.setCategoryIdActive(0))}
            >
              <div
                style={{
                  backgroundColor:
                    history.location.pathname === `/${item.page}`
                      ? '#b2c9ad'
                      : 'rgb(216, 227, 213)',
                  transform: 'translateX(-10px) skewX(340deg)',
                }}
                className={classes.boxMenu}
              >
                <React.Fragment key={`bc${index}`}>
                  <span
                    style={{
                      transform: 'skewX(20deg)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {' '}
                    {item.translate ? fm(item.translate) : item.title}{' '}
                  </span>
                  {length !== index + 1 && (
                    <span className='kt-subheader__breadcrumbs-separator' />
                  )}
                </React.Fragment>
                <span className='kt-subheader__breadcrumbs-separator' />
              </div>
            </Link>
          ))
        : items.map(
            (item, index) =>
              item && (
                <Link
                  style={{ fontSize: '1rem', fontWeight: 200, zIndex: 2 }}
                  className={`kt-subheader__breadcrumbs-link sub-menu ${
                    history.location.pathname === `/${item.page}` ? 'active' : ''
                  }`}
                  to={`/${item.page}`}
                  onClick={(e) => {
                    item.disabled && e.preventDefault();
                    dispatch(actions.setCategoryIdActive(0));
                    item.click && item.click();
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        history.location.pathname === `/${item.page}`
                          ? '#b2c9ad'
                          : 'rgb(216, 227, 213)',
                    }}
                    className={classes.box}
                  >
                    <React.Fragment key={`bc${index}`}>
                      <span
                        style={{
                          transform: 'skewX(20deg)',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {' '}
                        {item.translate ? fm(item.translate) : item.title}{' '}
                      </span>
                      {length !== index + 1 && (
                        <span className='kt-subheader__breadcrumbs-separator' />
                      )}
                    </React.Fragment>
                    <span className='kt-subheader__breadcrumbs-separator' />
                  </div>
                </Link>
              )
          )}
    </div>
  );
}

export default BreadCrumbs;
