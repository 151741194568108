/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { Link, useHistory, withRouter } from 'react-router-dom';
import BreadCrumbs from './components/BreadCrumbs';
import { LayoutContextConsumer } from '../LayoutContext';
import * as builder from '../../ducks/builder';
import SearchField from '../../../app/components/ui/SearchField';
import ButtonAddProduct from '../../../app/components/ui/Buttons/ButtonAddProduct';
import { UserRoles } from '../../../app/interfaces/user';
import ButtonNavigation from '../../../app/components/ui/Buttons/ButtonNavigation';
import HMenu from '../header/HMenu/HMenu';
import { ArrowBackIos } from '@material-ui/icons';
import { getFullCategories } from '../../../app/crud/categories.crud';
import { actions } from '../../../app/store/ducks/product-catalog.duck';
import { actions as actions2 } from '../../../app/store/ducks/categories.duck';

// import { QuickActions } from './components/QuickActions';
// import { ReactComponent as SortNum1Icon } from '../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg';

class SubHeader extends React.Component {
  componentDidMount() {
    if (this.props.catalogCategories) {
      getFullCategories().then(res => {
        this.props.dispatch(actions2.successCatalogCategories(res.data));
      });
    }
  }

  render() {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle,
      userRoles,
      me,
      history,
      isAuthorized,
      dispatch,
      catalogCategories,
    } = this.props;
    const isVendor = me?.roles?.includes('ROLE_VENDOR');

    return (
      <LayoutContextConsumer>
        {/*{({ subheader: { title, breadcrumb } }) => (*/}
        {({ subheader: { title, breadcrumb, description, show, mode, isSubmenu } }) =>
          show && (
            <div style={{padding: "0 25px"}}>

            <div
              id='kt_subheader'
              className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
              style={{margin: '0 auto', width: '100%', maxWidth: 1440}}
            >
              <div>{mode}</div>
              <div className={`kt-container ${subheaderContainerCssClasses}`} style={{padding: 0}}>
                <div className='kt-subheader__main'>
                  <>
                    {subheaderMobileToggle && (
                      <button
                        className='kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left'
                        id='kt_subheader_mobile_toggle'
                      >
                        <span />
                      </button>
                    )}

                    <div className='kt-subheader__wrap-left'>
                      {breadcrumb && (
                        <BreadCrumbs
                          items={breadcrumb}
                          isSubmenu={isSubmenu}
                          userRoles={userRoles}
                          isAuthorized={isAuthorized}
                        />
                      )}
                      {title && description && (
                        <span className='kt-subheader__separator kt-subheader__separator--v' />
                      )}
                      {title && (
                        <a
                          style={{
                            fontSize: '18px',
                            fontWeight: 300,
                            zIndex: 2,
                            cursor: 'pointer',
                          }}
                        >
                          <div
                            onClick={() => {
                              catalogCategories?.forEach(el => {
                                el.children.forEach(child => {
                                  if (child.name === title) {
                                    dispatch(actions.setCategoryIdActive(child.id));
                                    this.props.history.push('/products/catalog');
                                  }
                                });
                              });
                            }}
                            style={{
                              position: 'relative',
                              width: '100%',
                            //  height: '2.8rem',
                              height: '50px',
                              justifyContent: 'center',
                              margin: '0.5rem 1rem',
                              transform: 'translateX(-26px)',
                              padding: '0 15px',
                            }}
                            className='kt-subheader__breadcrumbs'
                          >
                            <div
                              style={{
                                position: 'absolute',
                                zIndex: 0,
                                width: '100%',
                               // height: '2.8rem',
                                height: '50px',
                                backgroundColor: '#d8e3d5',
                                transform: 'translateX(-10px) skewX(340deg)',
                                marginLeft: '1rem',
                              }}
                            />
                            {title && (
                              <h3
                                className='kt-subheader__title'
                                style={{ fontWeight: 200, zIndex: 2 }}
                              >
                                {title}
                              </h3>
                            )}
                          </div>
                        </a>
                      )}
                      {this.props.location.pathname === '/products/catalog' ||
                      (this.props.location.pathname === '/products/company' && !isVendor) ? (
                        <ButtonAddProduct />
                      ) : null}
                      {/* {this.props.location.pathname.includes('/navigation/') && ( */}
                      {/*   <ButtonNavigation */}
                      {/*     type={this.props.location.pathname.replace('/navigation/', '')} */}
                      {/*   /> */}
                      {/* )} */}
                      {/* <div className='kt-header sub-menu' style={{height: '100%'}}> */}
                      {/*   <HMenu /> */}
                      {/* </div> */}
                    </div>
                    <button
                      onClick={() => history.goBack()}
                      className='kt-subheader__btn-back'
                    >
                      <ArrowBackIos style={{marginLeft:7}} />
                    </button>
                    {/* { */}
                    {/*   !me || !me?.roles.includes(UserRoles.ROLE_BUYER) && ( */}
                    {/*     this.props.location.pathname === '/products/catalog' && <SearchField isSubHeader={true} /> */}
                    {/*   ) */}
                    {/* } */}
                    {/* {userRoles?.[0] !== 'ROLE_VENDOR' && (
                    <Link to={`/cart`} className="kt-menu__link ">
                      <i className={`kt-menu__link-icon flaticon-cart`} />
                      <span
                        className="kt-menu__link-text"
                        style={{ paddingLeft: 10, fontWeight: 400 }}
                      >
                        <FormattedMessage
                          id="CART.SUBHEADER.TITLE"
                          defaultMessage={'CART.SUBHEADER.TITLE'}
                        />
                      </span>
                    </Link>
                  )} */}
                    {description && <span className='kt-subheader__desc'>{description}</span>}
                  </>
                </div>
              </div>
            </div>
            </div>
          )
        }
      </LayoutContextConsumer>
    );
  }
}

const mapStateToProps = store => ({
  catalogCategories: store.categories.catalogCategories,
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  userRoles: store.auth.user?.roles,
  subheaderMobileToggle: objectPath.get(store.builder.layoutConfig, 'subheader.mobile-toggle'),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true,
  }),
  me: store.profile.me,
  isAuthorized: store.auth.user != null,
});

export default withRouter(connect(mapStateToProps)(SubHeader));
