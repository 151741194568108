import React, { useState, useCallback, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
  darken,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { dark } from "@material-ui/core/styles/createPalette";
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { ReactComponent as Clear } from '../../../../_metronic/layout/assets/layout-svg-icons/clear-icon.svg';
import { ReactComponent as Search } from '../../../../_metronic/layout/assets/layout-svg-icons/search-icon.svg';
import { ReactComponent as Home } from '../../../../_metronic/layout/assets/layout-svg-icons/home.svg';
import { ReactComponent as HomeActive } from '../../../../_metronic/layout/assets/layout-svg-icons/home_active.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    textFieldWrapper: {
      width: '100%',
      maxWidth: 'calc(100% - 326px - 30px)',
      minWidth: 'calc(326px)',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        minWidth: '100%',
        marginTop: 15,
        marginBottom: 15,
      },
    },
    textFieldWrapperSubHeader: {
      width: '100%',
      maxWidth: 'calc(100%)',
      display: 'flex',
      alignItems: 'center',
      margin: '0 20px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        marginTop: 15,
        marginBottom: 15,
        margin: '0 20px',
      },
    },
    textField: {
      width: '100%',
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
        color: '#002626',
        fontWeight: 500,
      },
      '& .MuiButton-root': {
        borderRadius: '0 4px 4px 0',
      },
      '& .MuiButton-containedSizeLarge': {
        padding: '7px 22px',
      },
      '& .MuiButton-contained': {
        boxShadow: 'none',

      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: darken(theme.palette.primary.main, 0.3),

      }
    },
    clear: {
      padding: '0 10px 0 10px',
      fontWeight: 'bold',
      color: '#b5b5c3',
      cursor: 'pointer',
    },
    home: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 20,
      cursor: 'pointer',
    },
    homeText: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
  })
);

const SearchField = ({
  isSubHeader = false,
  clear,
}: {
  isSubHeader?: boolean;
  clear?: () => void;
}) => {
  const location = useLocation();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearchText] = useState<string>('');
  const { page, perPage, filter, isSearching } = useSelector(
    ({ productsCatalog }: any) => productsCatalog
  );
  const { fetchRequest, setSearch } = productCatalogActions;

  useEffect(() => {
    isSearching === false && setSearchText('');
  }, [isSearching]);

  const searchProducts = useCallback((page, perPage, filter, search, isRedirect?) => {
    let searchStatus;
    if (search === '') searchStatus = false;
    else searchStatus = true;
    dispatch(setSearch(searchStatus));
    dispatch(fetchRequest({ page, perPage, filter, search }));
    if (isRedirect) {
      if (history.location.pathname !== '/products/catalog') {
        history.push('/products/catalog/')
      }

    }
  }, []);

  return (
    <form
      className={isSubHeader ? classes.textFieldWrapper : classes.textFieldWrapperSubHeader}
      onSubmit={e => {
        e.preventDefault();
        searchProducts(page, perPage, filter, search, true);

      }}
    >
      <TextField
        className={classes.textField}
        variant='outlined'
        size='small'
        focused={isSubHeader ? undefined : true}
        placeholder={isSubHeader ? 'Искать товар' : 'Искать товар'}

        onChange={e => setSearchText(e.target.value)}
        value={search}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' style={{ margin: 0 }} >
              <span
                className={classes.clear}
                onClick={() => {
                  setSearchText('');
                  searchProducts(page, perPage, filter, '');
                }}
              >
                {isSubHeader ? intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' }) : <Clear />}
              </span>
              <Button
                style={
                  isSubHeader
                    ? {}
                    : {
                      maxWidth: 42,
                      minWidth: 42,
                      margin: 0,
                      padding: 0,
                      height: 35,
                    }
                }
                onClick={() => searchProducts(page, perPage, filter, search, true)}

                color='primary'
                variant='contained'
                size='large'
              >
                {isSubHeader ? (
                  intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })
                ) : (
                  <Search />
                )}
              </Button>
            </InputAdornment>
          ),
        }}
      />
      {/* {!isSubHeader && ( */}
      {/*   <Link */}
      {/*     className={classes.home} */}
      {/*     to='/products/catalog' */}
      {/*     onClick={() => { */}
      {/*       clear && clear(); */}
      {/*     }} */}
      {/*   > */}
      {/*     {location.pathname === '/products/catalog' ? <HomeActive /> : <Home />} */}
      {/*     <div */}
      {/*       className={`kt-menu__link-text  ${classes.homeText}`} */}
      {/*       style={{ */}
      {/*         fontWeight: 700, */}
      {/*         color: location.pathname === '/products/catalog' ? '#78DC78' : '#005A5A', */}
      {/*       }} */}
      {/*     > */}
      {/*       {intl.formatMessage({ id: 'MENU.DASHBOARD' })} */}
      {/*     </div> */}
      {/*   </Link> */}
      {/* )} */}
    </form >
  );
};

export default SearchField;
