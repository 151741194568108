import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress, makeStyles
} from '@material-ui/core';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useSnackbar } from 'notistack';
import {Close} from "@material-ui/icons";
import {getPublicDoc} from '../../../crud/docs.crud';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  contentType: string;
}
const useStyles = makeStyles(theme => ({
  content: {
    width: 544,
    height: 500,
    position: 'relative',
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: 500,
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paper': {
        padding:5,
      },
    },
  },
}));
const TermDialog: React.FC<IProps & WrappedComponentProps> = ({
  isOpen,
  handleClose,
  contentType,
  intl,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  function getMessage(pathname: string) {
    switch (pathname) {
      case 'user_agreement':
        return 'SUBMENU.LEGAL_TERMS';
      case 'privacy_policy':
        return 'SUBMENU.LEGAL_PRIVACY';
      case 'shipping_policy':
        return 'SUBMENU.LEGAL_SHIPPING';
      case 'refund_policy':
        return 'SUBMENU.LEGAL_REFUND';
      default:
        return 'DOC.USER_AGREEMENT';
    }
  }

  useEffect(() => {
    setLoading(true);
    if (contentType !== '') {
      getPublicDoc(contentType)
        .then(res => {
          setContent(res.data.data.text);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        });
    }
  }, [contentType]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <DialogTitle id="alert-dialog-title" >
        <div style={{ display:"flex",flexDirection:"row",justifyContent:"space-between"}}>  {intl.formatMessage({
          id: contentType ? getMessage(contentType) :'DOC.USER_AGREEMENT'
        })}
        <Close
            style={{ cursor: 'pointer' }}
            onClick={handleClose}
        />
        </div>
      </DialogTitle>
      <DialogContent
        // style={{
        //   flex:1,
        //   height: 400,
        //   position: 'relative',
        // }}
          className={classes.content}
      >
        {loading ? (
          <div style={loaderWrapStyle}>
            <CircularProgress size={20}/>
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} style={{color: 'black'}} />
        )}
      </DialogContent>
    </Dialog>
  );
};

const loaderWrapStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export default injectIntl(TermDialog);
