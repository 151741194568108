import { useMemo, useState } from 'react';

export const useHandleCountrySelect = (countries: any[] | null, initCountryId: number = 76) => {
  const [countryId, setCountryId] = useState(initCountryId);

  const countryData = useMemo(() => {
    if (!countries) return null;
    return countries.find(el => el.id === countryId);
  }, [countries, countryId]);

  if (countryData) {
    countryData.mask = countryData.mask.split('-').join(' ')
  }

  return { countryId, setCountryId, countryData };
};
