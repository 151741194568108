import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { connect, ConnectedProps, useSelector } from 'react-redux';

import { useLastLocation } from 'react-router-last-location';
import { LayoutSplashScreen } from '../../../_metronic';
import getMenuConfig from '../../router/MenuConfig';
import * as builder from '../../../_metronic/ducks/builder';
import { actions, actions as cartActions } from '../../store/ducks/cart.duck';
import { actions as profileActions } from '../../store/ducks/profile.duck';
import { IAppState } from '../../store/rootDuck';
import Preloader from '../../components/other/Preloader/Preloader';
import CompanyView from './companies/CompanyView';
import ProductView from './products/ProductView';
// import BlogPage from './blog';
import NavigationPage from './navigation/NavigationPage';
import * as routerHelpers from '../../router/RouterHelpers';
import CookiesDialog from '../../components/other/Dialog/CookiesDialog';
import VerificationAge from '../../components/other/Dialog/VerificationAge';
import { PrivateRoute } from '../../router/Routes';
// import { DeliveryType } from './cart/Cart';

const CategoriesPage = lazy(() => import('./categories'));
const UsersPage = lazy(() => import('./users'));
// const PromocodesPage = lazy(() => import('./promocodes'));
const CompaniesPage = lazy(() => import('./companies'));
const UserDocPage = lazy(() => import('./userDocs'));
const SettingsPage = lazy(() => import('./settings'));
const ProductsPage = lazy(() => import('./products'));
const DashboardPage = lazy(() => import('./dashboard'));
const OrdersPage = lazy(() => import('./orders'));
const StorePage = lazy(() => import('./store'));
const CartPage = lazy(() => import('./cart'));
const BannersPage = lazy(() => import('./banners'));
// const TariffsPage = lazy(() => import('./tariffs'));
// const CollectionsPage = lazy(() => import('./collections'));
// const TransactionsPage = lazy(() => import('./transactions'));
// const RefferalsPage = lazy(() => import('./referrals'));
// const ChatsPages = lazy(() => import('./chats'));
// const TendersPage = lazy(() => import('./tenders'));
const DeliveryPage = lazy(() => import('./delivery'));
// const SupplyPage = lazy(() => import('./supply'));
const GroupsPage = lazy(() => import('./groups'));
const PayPage = lazy(() => import('./pay'));
const MainPage = lazy(() => import('./main'));

const HomePage: React.FC<TPropsFromRedux & { userLastLocation: any }> = ({
  userRoles,
  isAuthorized,
  setMenuConfig,
  getOrCreateCart,
  getOrCreateCartLoading,
  getLocalGuestCart,
  isAdmin,
  fetchMe,
  checkout,
}) => {
  const [menuConfig] = useState(getMenuConfig(userRoles, isAuthorized));
  const history = useHistory();
  const isVendor = userRoles?.includes('ROLE_VENDOR');
  const isBuyer = userRoles?.includes('ROLE_BUYER');
  const isManager = userRoles?.includes('ROLE_MANAGER');
  const [ageAccepted, setAgeAccepted] = useState(false);
  const checkoutSuccess = useSelector((state: IAppState) => state.cart.checkoutSuccess);
  const isAgeAccepted = localStorage.getItem('ageAccepted') === 'true';
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [addedToLocal, setAddedToLocal] = useState(false);
  const questCartForm = localStorage.getItem('questCartForm');
  const localCartForm =
    questCartForm && questCartForm !== 'undefined'
      ? JSON.parse(questCartForm)
      : JSON.parse('{}');

  // const localCartForm = JSON.parse('{}');
  const isCookieAccepted =
    localStorage.getItem('cookieAccepted') === 'accepted' ||
    localStorage.getItem('cookieAccepted') === 'necessary';

  const handleAcceptAge = () => {
    localStorage.setItem('ageAccepted', 'true');
    setAgeAccepted(false);
  };
  const handleCancelAge = (reason: string) => {
    localStorage.removeItem('ageAccepted');
    if (reason && reason === 'backdropClick') return;
    setAgeAccepted(false);
  };

  const handleAcceptCookie = (value: string) => {
    localStorage.setItem('cookieAccepted', value);
    setCookieAccepted(false);
  };
  const handleCancelCookie = (reason: string) => {
    localStorage.removeItem('cookieAccepted');
    if (reason && reason === 'backdropClick') return;
    setCookieAccepted(false);
  };

  useEffect(() => {
    if (!isCookieAccepted) {
      if (!cookieAccepted) {
        setTimeout(() => {
          setCookieAccepted(true);
        }, 3000);
      }
    } else {
      setCookieAccepted(false);
    }
    if (!isAgeAccepted) {
      if (!ageAccepted) {
        setTimeout(() => {
          setAgeAccepted(true);
        }, 3000);
      }
    } else {
      setAgeAccepted(false);
    }
  }, []);

  const lastLocation = useLastLocation();

  routerHelpers.saveLastLocation(lastLocation);

  routerHelpers.saveLastLocation(lastLocation);

  // for user profile in header
  useEffect(() => {
    fetchMe();
  }, []);

  useEffect(() => {
    setMenuConfig(menuConfig);
  }, [setMenuConfig, menuConfig]);

  // Проверка на существование корзины у пользователя
  useEffect(() => {
    const localCart = localStorage.getItem('guestCart');
    if (isBuyer) {
      if (localCart) {
        const localLocation = localStorage.getItem('location');
        const parseLocalLocation = localLocation && JSON.parse(localLocation);
        if (parseLocalLocation) {
          localStorage.removeItem('location');
        }
        history.replace('/cart');
      }
    }
    getOrCreateCart();
  }, [isBuyer]);

  // useEffect(() => {

  //   if (localCartForm && Object.keys(localCartForm)?.length && !checkoutSuccess) {
  //     checkout({
  //       data: {
  //         ...localCartForm,
  //         phoneNumbers: localCartForm.phone || '',
  //         countryId: localCartForm.id,
  //         name: localCartForm.name || '',
  //         surname: localCartForm.surname || '',
  //         comment: localCartForm.comment || '',
  //         email: localCartForm.email || '',
  //         received_at: moment(localCartForm.received_at).format('YYYY-MM-DD[T]HH:mm:ss'),
  //         address:
  //           localCartForm.deliveryType === DeliveryType.delivery
  //             ? localCartForm.shippingLocation?.address
  //             : undefined,
  //         billing_address:
  //           localCartForm.deliveryType === DeliveryType.delivery
  //             ? localCartForm.shippingLocation2?.address
  //             : undefined,
  //         lat:
  //           localCartForm.deliveryType === DeliveryType.delivery
  //             ? localCartForm.shippingLocation?.lat
  //             : undefined,
  //         lng:
  //           localCartForm.deliveryType === DeliveryType.delivery
  //             ? localCartForm.shippingLocation?.lng
  //             : undefined,
  //         storeId: localCartForm.storeId,
  //         locationId: localCartForm.locationId || 0,
  //         payType: localCartForm.payType,
  //         locationId2: localCartForm.locationId2 || 0,
  //         deliveryType: localCartForm.deliveryType2 ? 'delivery' : 'self',
  //         region: localCartForm.region,
  //       },
  //       skipPay: localCartForm.payType === 'cash',
  //     });
  //   }
  //   if (checkoutSuccess) {
  //     localStorage.removeItem('questCartForm');
  //   }
  // }, [checkoutSuccess, localCartForm]);

  useEffect(() => {
    if (localCartForm?.contactSkip) {
      localStorage.removeItem('questCartForm');
      history.push('/cart/delivery');
    }
  }, [localCartForm]);

  useEffect(() => {
    !isAuthorized && getLocalGuestCart();
  }, [isAuthorized, getLocalGuestCart]);

  if (getOrCreateCartLoading) return <Preloader />;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* { */}
        {/*   (isAdmin || isManager) ? ( */}
        {/*     <Redirect exact from='/' to='products/catalog' /> */}
        {/*   ) : ( */}
        {/*     <Redirect exact from='/' to='products/list' /> */}
        {/*   ) */}
        {/* } */}
        <Redirect
          exact
          from='/'
          to={isVendor || isAdmin || isManager ? 'products/dashboard' : 'main/catalog'}
        />
        <PrivateRoute path='/settings' component={SettingsPage} isAuthorized={isAuthorized} />
        <Route path='/navigation/:type' exact component={NavigationPage} />

        <PrivateRoute
          path='/categories'
          component={CategoriesPage}
          isAuthorized={isAuthorized}
        />
        <Route path='/products' component={ProductsPage} />
        <PrivateRoute
          path='/dashboard'
          component={DashboardPage}
          isAuthorized={isAuthorized}
        />
        <PrivateRoute path='/documents' component={UserDocPage} isAuthorized={isAuthorized} />
        <PrivateRoute path='/users' component={UsersPage} isAuthorized={isAuthorized} />
        <PrivateRoute
          path='/companies'
          component={CompaniesPage}
          isAuthorized={isAuthorized}
        />
        <PrivateRoute path='/orders' component={OrdersPage} isAuthorized={isAuthorized} />
        <PrivateRoute path='/stores' component={StorePage} isAuthorized={isAuthorized} />
        <PrivateRoute path='/banners' component={BannersPage} isAuthorized={isAuthorized} />
        {isBuyer || !isAuthorized ? (
          <Route path='/cart' component={CartPage} />
        ) : (
          <Redirect
            exact
            from='/cart'
            to={isVendor || isAdmin || isManager ? 'products/dashboard' : 'products/catalog'}
          />
        )}
        {/* <Route path='/blog' component={BlogPage} /> */}
        <Route path='/viewproduct/:id' component={ProductView} />
        <Route path='/viewcompany/:id' component={CompanyView} />
        {/* <Route path='/tariffs' component={TariffsPage} /> */}
        {/* <Route path='/collections' component={CollectionsPage} /> */}
        {/* <Route path='/promocodes' component={PromocodesPage} /> */}
        {/* <Route path='/refferals' component={RefferalsPage} /> */}
        {/* <Route path='/transactions' component={TransactionsPage} /> */}
        {/* <Route path='/chats' component={ChatsPages} /> */}
        {/* <Route path='/tenders' component={TendersPage} /> */}
        {/* <Route path='/delivery' component={DeliveryPage} /> */}
        <PrivateRoute path='/delivery' component={DeliveryPage} isAuthorized={isAuthorized} />
        {/* <Route path='/supply' component={SupplyPage} /> */}
        <PrivateRoute path='/groups' component={GroupsPage} isAuthorized={isAuthorized} />
        <Route path='/pay_process' component={PayPage} />
        <Route path='/main' component={MainPage} />

        <Redirect to='/error/error-v1' />
      </Switch>
      {!isAuthorized ? (
        <>
          <VerificationAge
            open={ageAccepted}
            handleClose={handleCancelAge}
            handleAgree={handleAcceptAge}
          />
          {isAgeAccepted && !isCookieAccepted && (
            <>
              <CookiesDialog
                open={cookieAccepted}
                handleClose={handleCancelCookie}
                handleAgree={handleAcceptCookie}
              />
            </>
          )}
        </>
      ) : (
        <>
          {!isCookieAccepted && isBuyer && (
            <>
              <CookiesDialog
                open={cookieAccepted}
                handleClose={handleCancelCookie}
                handleAgree={handleAcceptCookie}
              />
            </>
          )}
        </>
      )}
    </Suspense>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
    isAuthorized: state.auth.user != null,
    getOrCreateCartLoading: state.cart.getOrCreateCartLoading,
    isAdmin: state.auth.user?.is_admin,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    editMe: profileActions.editRequest,
    checkout: actions.checkoutRequest,
    setMenuConfig: builder.actions.setMenuConfig,
    getOrCreateCart: cartActions.getOrCreateRequest,
    getLocalGuestCart: cartActions.getLocalGuestCart,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
