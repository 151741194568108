import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { call, put, takeLatest } from 'redux-saga/effects';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { checkHash, getResponseMessage } from '../../utils/utils';
import { IServerResponse } from '../../interfaces/server';
import {
  addProduct,
  cartCheckout,
  cartEdit,
  clearCart,
  createCart,
  getCart,
  setProductCount,
} from '../../crud/cart.crud';
import { ICart, ICartCheckout, IGuestCart } from '../../pages/home/cart/interfaces';
import { IStore } from '../../interfaces/store';
import { getStores } from '../../crud/store.crud';
import { IProduct } from '../../interfaces/product';
import { IOrder } from '../../interfaces/order';

const CLEAR_ADD_PRODUCT = 'orders/CLEAR_ADD_PRODUCT';
const ADD_PRODUCT_REQUEST = 'orders/ADD_PRODUCT_REQUEST';
const ADD_PRODUCT_SUCCESS = 'orders/ADD_PRODUCT_SUCCESS';
const ADD_PRODUCT_FAIL = 'orders/ADD_PRODUCT_FAIL';

const CLEAR_CART_REQUEST = 'orders/CLEAR_CART';
const CLEAR_CART_SUCCESS = 'orders/CLEAR_CART_SUCCESS';
const CLEAR_CART_FAIL = 'orders/CLEAR_CART_FAIL';

const GET_OR_CREATE_CART_REQUEST = 'orders/GET_OR_CREATE_CART_REQUEST';
const GET_OR_CREATE_CART_SUCCESS = 'orders/GET_OR_CREATE_CART_SUCCESS';
const GET_OR_CREATE_CART_FAIL = 'orders/GET_OR_CREATE_CART_FAIL';

const CLEAR_SET_PRODUCT_COUNT = 'orders/CLEAR_SET_PRODUCT_COUNT';
const SET_PRODUCT_COUNT_REQUEST = 'orders/SET_PRODUCT_COUNT_REQUEST';
const SET_PRODUCT_COUNT_SUCCESS = 'orders/SET_PRODUCT_COUNT_SUCCESS';
const SET_PRODUCT_COUNT_FAIL = 'orders/SET_PRODUCT_COUNT_FAIL';
const SET_VERIFY_TEL = 'orders/SET_VERIFY_TEL';
const SET_VALID_CHECKBOX = 'orders/SET_VALID_CHECKBOX';

const CLEAR_CHECKOUT = 'orders/CLEAR_CHECKOUT';
const CHECKOUT_REQUEST = 'orders/CHECKOUT_REQUEST';
const CHECKOUT_REQUEST_PAY = 'orders/CHECKOUT_REQUEST_PAY';
const CHECKOUT_SUCCESS = 'orders/CHECKOUT_SUCCESS';
const CHECKOUT_FAIL = 'orders/CHECKOUT_FAIL';

const CLEAR_STORES = 'orders/CLEAR_STORES';
const STORES_REQUEST = 'orders/STORES_REQUEST';
const STORES_SUCCESS = 'orders/STORES_SUCCESS';
const STORES_FAIL = 'orders/STORES_FAIL';
const SET_PRODUCT_GUEST_CART = 'orders/SET_PRODUCT_GUEST_CART';
const CLEAR_GUEST_CART = 'orders/CLEAR_GUEST_CART';
const GET_LOCAL_GUEST_CART = 'orders/GET_LOCAL_GUEST_CART';
const SET_COUNT_GUEST_CART = 'orders/SET_COUNT_GUEST_CART';
const SET_USER_INFO_GUEST_CART = 'orders/SET_USER_INFO_GUEST_CART';
const SET_PAY_CART = 'orders/SET_PAY_CART';
const CLEAR_PAY_CART = 'orders/CLEAR_PAYED_CART';
const CREATE_CART_BEFORE_CHECKOUT = 'orders/CREATE_CART_BEFORE_CHECKOUT';

const SET_REDIRECT = 'orders/SET_REDIRECT';

export interface IInitialState {
  cart: ICart | null;
  companyId: number | null;
  stores: IStore[];

  addProductLoading: boolean;
  addProductSuccess: boolean;
  addProductError: string | null;

  getOrCreateCartLoading: boolean;
  getOrCreateCartSuccess: boolean;
  getOrCreateCartError: string | null;

  getCartLoading: boolean;
  getCartSuccess: boolean;
  getCartError: string | null;

  clearCartLoading: boolean;
  clearCartSuccess: boolean;
  clearCartError: string | null;

  setProductCountLoading: boolean;
  setProductCountSuccess: boolean;
  setProductCountError: string | null;

  checkoutLoading: boolean;
  checkoutSuccess: boolean;
  checkoutError: string | null;

  storesLoading: boolean;
  storesSuccess: boolean;
  storesError: string | null;
  guestCart: IGuestCart | null;
  verifyTel: boolean;
  payCart: number | null;
  redirectCart: boolean;
  validCheckbox: boolean
}

const initialState: IInitialState = {
  cart: null,
  companyId: null,
  stores: [],

  addProductLoading: false,
  addProductSuccess: false,
  addProductError: null,
  verifyTel: true,
  validCheckbox: false,

  getOrCreateCartLoading: false,
  getOrCreateCartSuccess: false,
  getOrCreateCartError: null,

  getCartLoading: false,
  getCartSuccess: false,
  getCartError: null,

  clearCartLoading: false,
  clearCartSuccess: false,
  clearCartError: null,

  setProductCountLoading: false,
  setProductCountSuccess: false,
  setProductCountError: null,

  checkoutLoading: false,
  checkoutSuccess: false,
  checkoutError: null,

  storesLoading: false,
  storesSuccess: false,
  storesError: null,
  guestCart: null,
  payCart: null,
  redirectCart: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'orders', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_ADD_PRODUCT: {
        return {
          ...state,
          addProductLoading: false,
          addProductSuccess: false,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_REQUEST: {
        return {
          ...state,
          addProductLoading: true,
          addProductSuccess: false,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_SUCCESS: {
        const cart = action.payload.data;
        const companyId =
          cart && cart.items.length ? cart.items[0].product?.company?.id || null : null;

        return {
          ...state,
          companyId,
          cart,
          addProductLoading: false,
          addProductSuccess: true,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_FAIL: {
        return {
          ...state,
          addProductLoading: false,
          addProductSuccess: false,
          addProductError: action.payload,
        };
      }

      case GET_OR_CREATE_CART_REQUEST: {
        return {
          ...state,
          getOrCreateCartLoading: true,
          getOrCreateCartSuccess: false,
          getOrCreateCartError: null,
        };
      }
      case GET_OR_CREATE_CART_SUCCESS: {
        const cart = action.payload.data;
        const companyId =
          cart && cart.items.length ? cart.items[0].product?.company?.id || null : null;

        return {
          ...state,
          companyId,
          cart,
          getOrCreateCartLoading: false,
          getOrCreateCartSuccess: true,
          getOrCreateCartError: null,
        };
      }
      case GET_OR_CREATE_CART_FAIL: {
        return {
          ...state,
          getOrCreateCartLoading: false,
          getOrCreateCartSuccess: false,
          getOrCreateCartError: action.payload,
        };
      }

      case CLEAR_CART_REQUEST: {
        return {
          ...state,
          clearCartLoading: true,
          clearCartSuccess: false,
          clearCartError: null,
        };
      }

      case SET_VERIFY_TEL: {
        return {
          ...state,
          verifyTel: action.payload,
        };
      }

      case SET_VALID_CHECKBOX: {
        return {
          ...state,
          validCheckbox: action.payload,
        };
      }
      

      case CLEAR_CART_SUCCESS: {
        return {
          ...state,
          companyId: null,
          cart: null,
          guestCart: null,
          clearCartLoading: false,
          clearCartSuccess: true,
          clearCartError: null,
        };
      }
      case CLEAR_CART_FAIL: {
        return {
          ...state,
          clearCartLoading: false,
          clearCartSuccess: false,
          clearCartError: action.payload,
        };
      }

      case CLEAR_SET_PRODUCT_COUNT: {
        return {
          ...state,
          setProductCountLoading: false,
          setProductCountSuccess: false,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_REQUEST: {
        return {
          ...state,
          setProductCountLoading: true,
          setProductCountSuccess: false,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_SUCCESS: {
        const cart = action.payload.data;
        const companyId = cart && cart.items.length ? state.companyId : null;

        return {
          ...state,
          companyId,
          cart,
          setProductCountLoading: false,
          setProductCountSuccess: true,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_FAIL: {
        return {
          ...state,
          setProductCountLoading: false,
          setProductCountSuccess: false,
          setProductCountError: action.payload,
        };
      }

      case CLEAR_CHECKOUT: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: false,
          checkoutError: null,
          redirectCart: false,
        };
      }
      case CHECKOUT_REQUEST: {
        return {
          ...state,
          checkoutLoading: true,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }
      case CHECKOUT_REQUEST_PAY: {
        return {
          ...state,
          checkoutLoading: true,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }
      case CHECKOUT_SUCCESS: {
        localStorage.setItem('hash', action.payload.data.cart_hash);

        return {
          ...state,
          cart: action.payload.data,
          companyId: null,
          checkoutLoading: false,
          checkoutSuccess: true,
          checkoutError: null,
        };
      }
      case CHECKOUT_FAIL: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: false,
          checkoutError: action.payload,
        };
      }

      case SET_PAY_CART: {
        return {
          ...state,
          payCart: action.payload,
        };
      }

      case CLEAR_PAY_CART: {
        return {
          ...state,
          payCart: null,
        };
      }

      case CLEAR_STORES: {
        return {
          ...state,
          stores: [],
          storesLoading: false,
          storesSuccess: false,
          storesError: null,
        };
      }
      case STORES_REQUEST: {
        return {
          ...state,
          storesLoading: true,
          storesSuccess: false,
          storesError: null,
        };
      }
      case STORES_SUCCESS: {
        return {
          ...state,
          stores: action.payload.data,
          storesLoading: false,
          storesSuccess: true,
          storesError: null,
        };
      }
      case STORES_FAIL: {
        return {
          ...state,
          storesLoading: false,
          storesSuccess: false,
          storesError: action.payload,
        };
      }

      case SET_PRODUCT_GUEST_CART: {
        if (state.guestCart && action.payload.type === 'cart') {
          const newGuestCart = state.guestCart;
          const guestProductIndex = newGuestCart.items.findIndex(
            item => item.product.id === action.payload.data.id
          );
          if (guestProductIndex > -1) {
            newGuestCart.items[guestProductIndex].count += 1;
          } else {
            newGuestCart.items = [
              ...newGuestCart.items,
              {
                count: 1,
                product: action.payload.data,
                product_price: action.payload.data.price,
              },
            ];
          }
          newGuestCart.order_summ += action.payload.data.price;
          newGuestCart.goods_num += 1;
          let newGoods = 0;
          let newSumm = 0;
          newGuestCart.items.forEach(i => {
            newGoods += i.count;
            newSumm += i.count * i.product_price;
          });
          newGuestCart.goods_num = newGoods;
          newGuestCart.order_summ = newSumm;
          if (newGuestCart.items.length === 0) {
            delete newGuestCart.cartCompanyId;
          } else if (
            newGuestCart.items[0].product.company?.id &&
            !newGuestCart.cartCompanyId
          ) {
            newGuestCart.cartCompanyId = newGuestCart.items[0].product.company?.id;
          }
          localStorage.setItem('guestCart', JSON.stringify(newGuestCart));
          return {
            ...state,
            guestCart: { ...newGuestCart },
          };
        }
        const startGuestCart: IGuestCart = {
          order_summ: action.payload.data.price,
          goods_num: 1,
          items: [
            {
              count: 1,
              product: action.payload.data,
              product_price: action.payload.data.price,
            },
          ],
          cartCompanyId: action.payload.data.company?.id,
          fio: '',
          email: '',
          phone: '',
          phoneNumbers: '',
          received_at: '',
          isPickup: false,
          comment: '',
          surname: '',
          name: '',
        };
        localStorage.setItem('guestCart', JSON.stringify(startGuestCart));
        return {
          ...state,
          guestCart: { ...startGuestCart },
        };
      }

      case GET_LOCAL_GUEST_CART: {
        if (state.guestCart) {
          return state;
        }
        const localCart = localStorage.getItem('guestCart');
        if (localCart) {
          return {
            ...state,
            guestCart: JSON.parse(localCart),
          };
        }
        return state;
      }

      case CLEAR_GUEST_CART: {
        localStorage.removeItem('guestCart');
        return {
          ...state,
          guestCart: null,
        };
      }

      case SET_COUNT_GUEST_CART: {
        const currentGuestCart = state.guestCart;
        if (currentGuestCart) {
          const indexCurrentProduct = currentGuestCart.items.findIndex(
            item => item.product.id === action.payload.product_id
          );
          const currentProduct = currentGuestCart.items[indexCurrentProduct];
          action.payload.type === 'dec'
            ? (currentGuestCart.goods_num -= 1)
            : (currentGuestCart.goods_num += 1);
          action.payload.type === 'dec'
            ? (currentGuestCart.order_summ -= currentProduct.product_price)
            : (currentGuestCart.order_summ += currentProduct.product_price);
          action.payload.count <= 0
            ? currentGuestCart.items.splice(indexCurrentProduct, 1)
            : (currentProduct.count = action.payload.count);
          let newGoods = 0;
          let newSumm = 0;
          currentGuestCart.items.forEach(i => {
            newGoods += i.count;
            newSumm += i.count * i.product_price;
          });
          currentGuestCart.goods_num = newGoods;
          currentGuestCart.order_summ = newSumm;
          if (currentGuestCart.items.length === 0) {
            delete currentGuestCart.cartCompanyId;
          } else if (
            currentGuestCart.items[0].product.company?.id &&
            !currentGuestCart.cartCompanyId
          ) {
            currentGuestCart.cartCompanyId = currentGuestCart.items[0].product.company?.id;
          }
          localStorage.setItem('guestCart', JSON.stringify(currentGuestCart));
          return {
            ...state,
            guestCart: { ...currentGuestCart },
          };
        }
        return state;
      }

      case SET_USER_INFO_GUEST_CART: {
        const currentCart = state.guestCart;
        if (currentCart) {
          const newCurrentCart = {
            ...currentCart,
            name: action.payload.name,
            surname: action.payload.surname,
            comment: action.payload.comment,
            email: action.payload.email,
            phoneNumbers: action.payload.phoneNumbers,
            countryId: action.payload.countryId,
            received_at: action.payload.received_at,
            isPickup: action.payload.isPickup,
          };
          localStorage.setItem('guestCart', JSON.stringify(newCurrentCart));
          return {
            ...state,
            guestCart: newCurrentCart,
          };
        }
        return state;
      }

      case SET_REDIRECT: {
        return {
          ...state,
          redirectCart: true,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearAddProduct: () => createAction(CLEAR_ADD_PRODUCT),
  addProductRequest: (payload: { product_id: number; count: number; newCart?: boolean }) =>
    createAction(ADD_PRODUCT_REQUEST, payload),
  addProductSuccess: (payload: IServerResponse<ICart>) =>
    createAction(ADD_PRODUCT_SUCCESS, payload),
  addProductFail: (payload: string) => createAction(ADD_PRODUCT_FAIL, payload),

  getOrCreateRequest: () => createAction(GET_OR_CREATE_CART_REQUEST),
  getOrCreateSuccess: (payload: IServerResponse<ICart>) =>
    createAction(GET_OR_CREATE_CART_SUCCESS, payload),
  getOrCreateFail: (payload: string) => createAction(GET_OR_CREATE_CART_FAIL, payload),

  clearCartRequest: () => createAction(CLEAR_CART_REQUEST),
  clearCartSuccess: () => createAction(CLEAR_CART_SUCCESS),
  clearCartFail: (payload: string) => createAction(CLEAR_CART_FAIL, payload),

  clearSetProductCount: () => createAction(CLEAR_SET_PRODUCT_COUNT),
  setProductCountRequest: (payload: { product_id: number; count: number }) =>
    createAction(SET_PRODUCT_COUNT_REQUEST, payload),
  setProductCountSuccess: (payload: IServerResponse<ICart>) =>
    createAction(SET_PRODUCT_COUNT_SUCCESS, payload),
  setProductCountFail: (payload: string) => createAction(SET_PRODUCT_COUNT_FAIL, payload),

  clearCheckout: () => createAction(CLEAR_CHECKOUT),
  checkoutRequest: (payload: { data: ICartCheckout; skipPay?: boolean }) =>
    createAction(CHECKOUT_REQUEST, payload),
  checkoutRequestPay: (payload: { data: ICartCheckout; skipPay?: boolean }) =>
    createAction(CHECKOUT_REQUEST_PAY, payload),
  checkoutSuccess: (payload: IServerResponse<ICart>) =>
    createAction(CHECKOUT_SUCCESS, payload),
  checkoutFail: (payload: string) => createAction(CHECKOUT_FAIL, payload),

  clearStores: () => createAction(CLEAR_STORES),
  storesRequest: (payload: { companyId: number }) => createAction(STORES_REQUEST, payload),
  storesSuccess: (payload: IServerResponse<IStore[]>) => createAction(STORES_SUCCESS, payload),
  storesFail: (payload: string) => createAction(STORES_FAIL, payload),
  setProductGuestCart: (payload: { data: IProduct; type?: 'new' | 'cart' }) =>
    createAction(SET_PRODUCT_GUEST_CART, payload),
  clearGuestCart: () => createAction(CLEAR_GUEST_CART),
  getLocalGuestCart: () => createAction(GET_LOCAL_GUEST_CART),
  setPayCart: (payload: number) => createAction(SET_PAY_CART, payload),
  clearPayedCart: () => createAction(CLEAR_PAY_CART),

  createCartBeforeCheckout: (payload: boolean) => createAction(CREATE_CART_BEFORE_CHECKOUT, payload),

  setCountGuestCart: (payload: { product_id: number; count: number; type: 'dec' | 'inc' }) =>
    createAction(SET_COUNT_GUEST_CART, payload),
  setInfoUserGuestCart: (payload: {
    name: string;
    surname: string;
    comment: string;
    email: string;
    phoneNumbers: string;
    received_at: string;
    isPickup: boolean;
    countryId?: number;
  }) => createAction(SET_USER_INFO_GUEST_CART, payload),
  setVerifyTel: (payload: boolean) => createAction(SET_VERIFY_TEL, payload),
  setRedirect: () => createAction(SET_REDIRECT),
  setValidCheckbox: (payload: boolean) => createAction(SET_VALID_CHECKBOX, payload)
};

export type TActions = ActionsUnion<typeof actions>;

function* addProductSaga({
  payload,
}: {
  payload: { product_id: number; count: number; newCart?: boolean };
}) {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());
    if (hash) {
      if (payload.newCart) {
        yield call(() => clearCart(hash));
      }
      const { data }: { data: IServerResponse<ICart> } = yield call(() =>
        addProduct({ product_id: payload.product_id, count: payload.count, hash })
      );
      yield put(actions.addProductSuccess(data));
      yield put(actions.clearAddProduct());
    }
  } catch (e) {
    yield put(actions.addProductFail(getResponseMessage(e)));
  }
}

function* getOrCreateCartSaga() {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());

    if (hash) {
      const guestCart = localStorage.getItem('guestCart');
      const parseGuestCart: IGuestCart | null = guestCart ? JSON.parse(guestCart) : null;
      if (parseGuestCart) {
        yield call(() =>
          cartEdit(
            parseGuestCart.name || '',
            parseGuestCart.surname || '',
            parseGuestCart.comment || '',
            parseGuestCart.email,
            parseGuestCart.phoneNumbers,
            hash,
            parseGuestCart.received_at,
            parseGuestCart.countryId
          )
        );
        for (const item of parseGuestCart.items) {
          yield call(() =>
            addProduct({ product_id: item.product.id!, count: item.count, hash })
          );
        }
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      } else {
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      }
      yield put(actions.clearGuestCart());
    } else {
      const { data }: { data: IServerResponse<any> } = yield call(() => createCart());
      const hash = data.data.cart_hash;
      localStorage.setItem('hash', hash);
      const guestCart = localStorage.getItem('guestCart');
      const parseGuestCart: IGuestCart | null = guestCart ? JSON.parse(guestCart) : null;
      if (parseGuestCart) {
        yield call(() =>
          cartEdit(
            parseGuestCart.name || '',
            parseGuestCart.surname || '',
            parseGuestCart.comment || '',
            parseGuestCart.email,
            parseGuestCart.phoneNumbers,
            hash,
            parseGuestCart.received_at,
            parseGuestCart.countryId
          )
        );
        for (const item of parseGuestCart.items) {
          yield call(() =>
            addProduct({ product_id: item.product.id!, count: item.count, hash })
          );
        }
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      } else {
        yield put(actions.getOrCreateSuccess(data));
      }
      yield put(actions.clearGuestCart());
    }
  } catch (e) {
    yield put(actions.getOrCreateFail(getResponseMessage(e)));
  }
}

function* clearCartSaga() {
  try {
    const hash = localStorage.getItem('hash');
    if (hash) {
      yield call(() => clearCart(hash));
      localStorage.removeItem('hash');
      yield put(actions.clearCartSuccess());
    }
  } catch (e) {
    yield put(actions.clearCartFail(getResponseMessage(e)));
  }
}

function* setProductCountSaga({
  payload,
}: {
  payload: { product_id: number; count: number };
}) {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());
    if (hash) {
      const { data }: { data: IServerResponse<ICart> } = yield call(() =>
        setProductCount({ product_id: payload.product_id, count: payload.count, hash })
      );
      yield put(actions.setProductCountSuccess(data));
      yield put(actions.clearSetProductCount());
    }
  } catch (e) {
    yield put(actions.setProductCountFail(getResponseMessage(e)));
  }
}

function* checkoutSaga({ payload }: { payload: { data: ICartCheckout; skipPay?: boolean } }) {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());

    if (hash) {
      const { data: payCart }: { data: IServerResponse<IOrder> } = yield call(() =>
        cartCheckout(payload.data, hash)
      );
      if (payload.skipPay) {
        yield put(actions.createCartBeforeCheckout(true));
      } else {
        yield put(actions.setPayCart(payCart.data.id));
      }
      // const { data }: { data: IServerResponse<any> } = yield call(() => createCart());
      // yield put(actions.checkoutSuccess(data));
    }
  } catch (e) {
    yield put(actions.checkoutFail(getResponseMessage(e)));
  }
}

function* checkoutSagaPay({
  payload,
}: {
  payload: { data: ICartCheckout; skipPay?: boolean };
}) {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());

    if (hash) {
      if (!payload.skipPay) {
        yield put(actions.setPayCart(0));
      }
    }
  } catch (e) {
    yield put(actions.checkoutFail(getResponseMessage(e)));
  }
}

function* createCartBeforeCheckoutSaga({ payload }: { payload: boolean }) {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() => createCart());
    yield put(actions.checkoutSuccess(data));
    if (payload){
      yield put(actions.setRedirect());
    }
  } catch (e) {
    yield put(actions.checkoutFail(getResponseMessage(e)));
  }
}

function* storesFetchSaga({ payload }: { payload: { companyId: number } }) {
  try {
    const { data }: { data: IServerResponse<IStore[]> } = yield call(() =>
      getStores(1, 999, payload.companyId)
    );
    yield put(actions.storesSuccess(data));
  } catch (e) {
    yield put(actions.storesFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.addProductRequest>>(
    ADD_PRODUCT_REQUEST,
    addProductSaga
  );
  yield takeLatest<ReturnType<typeof actions.getOrCreateRequest>>(
    GET_OR_CREATE_CART_REQUEST,
    getOrCreateCartSaga
  );
  yield takeLatest<ReturnType<typeof actions.clearCartRequest>>(
    CLEAR_CART_REQUEST,
    clearCartSaga
  );
  yield takeLatest<ReturnType<typeof actions.setProductCountRequest>>(
    SET_PRODUCT_COUNT_REQUEST,
    setProductCountSaga
  );
  yield takeLatest<ReturnType<typeof actions.checkoutRequest>>(CHECKOUT_REQUEST, checkoutSaga);
  yield takeLatest<ReturnType<typeof actions.checkoutRequestPay>>(
    CHECKOUT_REQUEST_PAY,
    checkoutSagaPay
  );
  yield takeLatest<ReturnType<typeof actions.storesRequest>>(STORES_REQUEST, storesFetchSaga);
  yield takeLatest<ReturnType<typeof actions.createCartBeforeCheckout>>(
    CREATE_CART_BEFORE_CHECKOUT,
    createCartBeforeCheckoutSaga
  );
}
