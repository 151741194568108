import { useCallback, useState } from 'react';
import axios from 'axios';
import {useIntl} from "react-intl";
import { useShowErrors } from '../../../hooks/useShowErrors';

export const useAxiosEnterPassword = () => {
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState<any>(null);
  const [err, setErr] = useState<any>(null);
  const intl = useIntl();

  const makeRequest = useCallback((email: string, password: string) => {
    const baseUrl = '/api/user/login';
    const formData = new FormData();
    formData.append('login', email);
    formData.append('password', password);

    setLoading(true);
    axios
      .post(baseUrl, formData)
      .then(res => setRes(res.data))
      .catch(err => {
        if (err) setErr(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_PASSWORD' }));
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
        setRes(null);
      });
  }, []);

  useShowErrors([err]);

  return { makeRequest, loading, res };
};
