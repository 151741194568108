import { toAbsoluteUrl } from '../../../../../_metronic';
import { API_DOMAIN } from '../../../../constants';
import { IProduct } from '../../../../interfaces/product';

export const getProductImage = (item: IProduct) => {
  let img = null;
  if (item.photos && item.photos.length > 0) {
    img = item?.photos?.find(i => !!i.main)?.small;
  }
  if (img) return `${API_DOMAIN}/${img}`;
  // return toAbsoluteUrl('/images/placeholder.png');
  return '';
};
