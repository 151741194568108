import { IntlShape } from 'react-intl';

import { IUser, UserRoles, UserRoleType } from '../../../../../interfaces/user';
import { UserListType, BUYER_ROLE, MANAGER_ROLE, VENDOR_ROLE } from '../../constants';

export const getRoleName = (roles: [UserRoleType], intl?: IntlShape): string => {
  if (roles.includes(UserRoles.ROLE_ADMIN)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.ADMIN' }) : UserRoles.ROLE_ADMIN;
  }
  if (roles.includes(UserRoles.ROLE_MANAGER)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.MANAGER' }) : UserRoles.ROLE_MANAGER;
  }
  if (roles.includes(UserRoles.ROLE_VENDOR)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.VENDOR' }) : UserRoles.ROLE_VENDOR;
  }
  return intl ? intl.formatMessage({ id: 'USER.ROLES.BUYER' }) : UserRoles.ROLE_USER;
};

export const getRole = (type: string) => {
  switch (type) {
    case UserListType.BUYER:
      return BUYER_ROLE;
    case UserListType.MANAGER:
      return MANAGER_ROLE;
    case UserListType.VENDOR:
      return VENDOR_ROLE;
    default:
      return BUYER_ROLE;
  }
};

export const getRoleBreadcrumbName = (type: string) => {
  switch (type) {
    case UserListType.BUYER:
      return 'SUBMENU.CUSTOMERS.LIST';
    case UserListType.MANAGER:
      return 'SUBMENU.TEAM';
    case UserListType.VENDOR:
      return 'SUBMENU.VENDORS';
    default:
      return 'SUBMENU.CUSTOMERS.LIST';
  }
};

export const getFio = (user: IUser) => {
  let ret = '';
  if (user.last_name) {
    ret += `${user.last_name}`;
  }
  if (user.first_name) {
    ret += ` ${user.first_name}`;
  }
  if (user.middle_name) {
    ret += ` ${user.middle_name}`;
  }
  if (ret === '') {
    return '-';
  }
  return ret;
};
