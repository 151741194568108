import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { makeStyles, useMediaQuery } from '@material-ui/core';

import { toAbsoluteUrl } from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import TermDialog from '../../components/other/Dialog/TermDialog';
import { AuthTabs } from './components';
import { IAppState } from '../../store/rootDuck';
import ChangePasswordPage from './ChangePasswordPage';
import Topbar from '../../../_metronic/layout/header/LanguageTopbar';


const useStyles = makeStyles(() => ({
  languageBlock: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
}));

export const AuthPage: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:1024px)');

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContentType, setDialogContentType] = useState('');

  const handleDialogOpen = useCallback(
    contentType => {
      setDialogContentType(contentType);
      setOpenDialog(true);
    },
    [setDialogContentType, setOpenDialog]
  );

  const store = useSelector((store: IAppState) => store, shallowEqual);

  const renderLinks = useCallback(
    () => (
      <div className='kt-grid__item'>
        <div
          className='kt-login__info'
          style={{ flexDirection: 'column', lineHeight: 'normal' }}
        >
          <div className='kt-login__copyright' style={{ marginBottom: 5 }}>
            <div className='kt-login__menu'>
              <a
                href='https://start-mobile.net'
                className='kt-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                {intl.formatMessage({ id: 'AUTH.CREATOR.COMPANY' })}
              </a>
            </div>
          </div>
          <div className='kt-login__menu'>
            {/* eslint-disable-next-line */}
            <a
              className='kt-link'
              onClick={() => handleDialogOpen('user_agreement')}
              style={{ marginRight: 0, whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id='SUBMENU.LEGAL_TERMS' />
            </a>
            <span
              style={{
                color: '#441861',
                marginRight: 4,
                marginLeft: 4,
              }}
            >
              |
            </span>
            {/* eslint-disable-next-line */}
            <a
              className='kt-link'
              onClick={() => handleDialogOpen('privacy_policy')}
              style={{ marginRight: 0, whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id='SUBMENU.LEGAL_PRIVACY' />
            </a>
          </div>
        </div>
      </div>
    ),
    []
  );

  // check if user is authorized
  useEffect(() => {
    if (store.auth.user) {
      history.push('/');
    }
  }, [store.auth.user]);

  return (
    <div
      className='kt-grid kt-grid--ver kt-grid--root'
      style={{
        minHeight: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
      }}
    >
      <div id='kt_login' className='kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1'>
        <div className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile'>
          <div
            className='kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
              height: matches ? '44vh' : undefined,
            }}
          >
            <div className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver'>
              <div className='kt-grid__item kt-grid__item--middle'>
                <h3 className='kt-login__title'>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img src={toAbsoluteUrl('/media/logos/logo.svg')} />
                  {/* {intl.formatMessage({ id: 'AUTH.WELCOME.TITLE' })} */}
                </h3>
                {/* <h4 className='kt-login__subtitle'> */}
                {/*   {intl.formatMessage({ id: 'AUTH.WELCOME.SUBTITLE' })} */}
                {/* </h4> */}
              </div>
            </div>
            {!matches && renderLinks()}
          </div>
          <div
            style={{
              paddingTop: matches ? 0 : undefined,
              paddingBottom: matches ? 0 : undefined,
            }}
            className='kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper'
          >
            <div className='kt-login__head'>
              <div className={classes.languageBlock}>
                <Topbar />
              </div>
            </div>

            <Switch>
              <Route path='/auth/main' component={AuthTabs} />
              <Route path='/auth/change_password/:code' component={ChangePasswordPage} />
              <Redirect from='/auth' exact to='/auth/main' />
              <Redirect to='/auth/main' />
            </Switch>
          </div>
          {matches && <div style={{ padding: '2rem 1.5rem' }}>{renderLinks()}</div>}
        </div>
      </div>
      <TermDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        contentType={dialogContentType}
      />
    </div>
  );
};
