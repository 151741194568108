export interface IProductType {
  id?: number;
  name: string;
  parameters?: ITypeParameter[];
}

export enum SortType {
  NEW = 'new',
  RATING = 'rating',
}

export interface ITypeParameter {
  id?: number;
  name?: string;
  type?: string;
  enum?: string[];
  value?: string | string[];
  parameter_id?: number;
  parameter_name?: string;
  required?: boolean;
  bulk_edit?: boolean;
  parameter_required?: boolean;
  position?: number | null;
  filter_type?: 'checkbox' | 'interval' | 'input' | null;
  in_group?: {
    id: number;
    name: string;
  };
  group_id?: number | null;
  group_position?: number | null;
  parameter_type?: string;
}
