import { API_DOMAIN } from '../../../../constants';
import { IProduct } from '../../../../interfaces/product';

type ProductImage = 'small' | 'big';
export const getProductImage = (item: IProduct, type: ProductImage = 'small') => {
  let img = null;
  if (item?.photos && item?.photos.length > 0) {
    img = item?.photos?.find(i => !!i.main)?.[type];
  }
  if (!img && item.avatar) return `${API_DOMAIN}/${item.avatar}`;
  if (img) return `${API_DOMAIN}/${img}`;
  // return toAbsoluteUrl('/images/placeholder.png');
  return '';
};
