import { useCallback, useMemo } from 'react';
import { IProduct } from '../../../../interfaces/product';

export const useFilterParams = (product?: IProduct) => {
  const sortParams = useCallback((id: number) => {
    const filterParams = product?.parameter_values?.filter(param => param.group_id === id);
    if (filterParams && filterParams.length > 0) {
      return filterParams.sort((a, b) => {
        const groupPositionA = a.group_position || 0;
        const groupPositionB = b.group_position || 0;
        return groupPositionA - groupPositionB;
      });
    }
    return undefined;
  }, [product]);

  const filter = useMemo(() => {
    const volume = sortParams(1);

    const marks = sortParams(2);

    const basic = sortParams(3);

    const serving = sortParams(4);

    const taste = sortParams(5);

    const location = sortParams(6);

    const description = sortParams(7);

    return [volume, marks, basic, serving, taste, location, description];
  }, [product]);

  return filter;
};
