import { TableCell, withStyles, createStyles } from '@material-ui/core';

export const SHeadTableCell = withStyles(() =>
  createStyles({
    head: {
      fontWeight: 'bold',
      fontSize: 14,
      paddingBottom: 17,
      paddingTop: 17,
    },
  })
)(TableCell);
