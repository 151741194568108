import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Typography, DialogProps } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import CompaniesTable from '../../../components/tableComponents/Table/CompaniesTable';
import { Modal } from '../../../components/other/Modals';

import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import homeStyles from '../../../constants/homeStyles';
import { IUser, IUserEditProps, TUserAttributes } from '../../../interfaces/user';
import { ICompany } from '../../../interfaces/companies';
import { IAppState } from '../../../store/rootDuck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { ButtonTheme } from '../../../components/ui/Buttons/ButtonWithLoader';
import { useAxiosGetCallback } from '../../../hooks/useAxiosGet';
import { useHandleCountrySelect } from '../../auth/hooks/useHandleCountrySelect';
import { cleanPhone } from '../../../utils/utils';

const CompanyBind: React.FC<{} & TPropsFromRedux> = ({
  searchCompany,
  editLoading,
  loadingCompanies,
  pageCompanies,
  perPageCompanies,
  totalCompanies,
  editCompany,
  companies,
  me,
  editSuccess,
  editError,
  clearEdit,
  fetchMe,
  edit,
  clearEditProfile,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const homeClasses = homeStyles();

  const [countries, countriesLoading, countriesErr, makeRequest] = useAxiosGetCallback<any>();
  const { countryId, setCountryId, countryData } = useHandleCountrySelect(countries, 1);
  const [openDialogCompanies, setOpenDialogCompanies] = useState(false);

  const getUserAttrCountry = useCallback((attributes: TUserAttributes) => {
    const countries = attributes.filter(el => el.type === 'country');
    return countries[0]?.value || '';
  }, []);

  const getUserAttrLanguages = useCallback((attributes: TUserAttributes) => {
    return attributes.filter(el => el.type === 'languages').map(el => el.value);
  }, []);

  // For connect company
  useCrudSnackbar({
    success: editSuccess,
    error: editError,
    successMessage: intl.formatMessage({ id: 'COMPANY.EDIT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${editError}`,
    afterSuccess: () => {
      fetchMe();
    },
    afterSuccessOrError: () => {
      setOpenDialogCompanies(false);
    },
  });

  useEffect(() => {
    if (me?.country?.id) {
      setCountryId(me.country.id);
    }
  }, [me]);

  useEffect(() => {
    makeRequest('/api/phone_codes');
  }, []);

  useEffect(
    () => () => {
      clearEdit();
      clearEditProfile();
    },
    []
  );

  const connectCompany = useCallback(
    (userId, company: ICompany) => {
      const body: IUserEditProps = {
        login: me?.login || '',
        email: me?.email || '',
        // @ts-ignore
        role: me?.roles && me?.roles?.length > 0 ? me.roles[0] : '',
        countryId: countryData.id,
        phoneNumbers: me?.phone_numbers
          ? cleanPhone(me.phone_numbers, `+${countryData?.code || '7'}`)
          : '',
        country: intl.locale === 'ru' ? countryData.ru_name : countryData.en_name,
        first_name: me?.first_name || '',
        last_name: me?.last_name || '',
        middle_name: me?.middle_name || '',
        countryCode: countryData.code || '7',
        companyId: company.id,
        locale: 'en',
        attributes: [
          {
            type: 'languages',
            // @ts-ignore
            value: me?.attributes ? getUserAttrLanguages(me.attributes) : [],
          },
          {
            type: 'country',
            value: me?.attributes ? getUserAttrCountry(me.attributes) : '',
          },
        ],
      };
      edit(body);
      // editCompany({
      //   id: company.id,
      //   data: { managers_ids: [...company.managers.map(i => i.id), userId] },
      // });
    },
    [me, countryData]
  );

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { inn: '' },
    validationSchema: Yup.object().shape({
      inn: Yup.string()
        .required(intl.formatMessage({ id: 'COMPANY.VALIDATOR.INN_ERQUIRED' }))
        .nullable(),
    }),
    onSubmit: () => {
      openCompaniesDialog();
    },
  });

  const openCompaniesDialog = useCallback(() => {
    searchCompany({ page: 1, perPage: 20, inn: values.inn });
    setOpenDialogCompanies(true);
  }, [values]);

  return (
    <>
      <Typography style={{ marginBottom: 20, marginTop: 20 }} component='h5' variant='h5'>
        {intl.formatMessage({ id: 'YOU_HAVE_NO_COMPANY' })}
      </Typography>
      <div className={homeClasses.form}>
        <Row>
          <Col>
            <form onSubmit={handleSubmit} autoComplete='off'>
              <TextField
                type='text'
                label={intl.formatMessage({ id: 'COMPANY.SEARCH.BY_INN' })}
                margin='normal'
                name='inn'
                value={values.inn}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.inn && errors.inn}
                error={Boolean(touched.inn && errors.inn)}
              />
              <ButtonWithLoader theme={ButtonTheme.SECONDARY} type='submit'>
                {intl.formatMessage({ id: 'COMPANY.SEARCH' })}
              </ButtonWithLoader>
            </form>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: 36 }}>
            <ButtonWithLoader
              theme={ButtonTheme.PRIMARY}
              onPress={() => history.push('/companies/new')}
            >
              {intl.formatMessage({ id: 'COMPANY.BUTTON.ADD' })}
            </ButtonWithLoader>
          </Col>
        </Row>
      </div>
      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openDialogCompanies}
        onClose={editLoading ? () => {} : () => setOpenDialogCompanies(false)}
        title={intl.formatMessage({ id: 'COMPANY.EDIT.MOUNT_TITLE' })}
        loading={editLoading || loadingCompanies}
        content={
          <CompaniesTable
            me={me as IUser}
            page={pageCompanies}
            perPage={perPageCompanies}
            total={totalCompanies}
            onConnectUser={company => {
              connectCompany(me?.id, company);
            }}
            companies={companies}
          />
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenDialogCompanies(false),
          },
        ]}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    user: state.users.user,
    me: state.profile.me,

    loadingCompanies: state.companies.searchLoading,
    pageCompanies: state.companies.page,
    perPageCompanies: state.companies.per_page,
    totalCompanies: state.companies.total,
    companies: state.companies.companies,

    editSuccess: state.profile.editSuccess,
    editLoading: state.profile.editLoading,
    editError: state.profile.editError,
  }),
  {
    clearEdit: companiesActions.clearEdit,
    searchCompany: companiesActions.searchRequest,
    editCompany: companiesActions.editRequest,
    fetchMe: profileActions.fetchRequest,
    edit: profileActions.editRequest,
    clearEditProfile: profileActions.clearEdit,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanyBind);
