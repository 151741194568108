import React, {FC, useCallback, useState} from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IProduct} from '../../../../../interfaces/product';
import {useDefineUserRole, useFormatMessage} from '../../../../../hooks';
import {IUser} from '../../../../../interfaces/user';
import {useCreateChat} from '../../../chats/chatsPage/hooks/useCreateChat';
import {IAppState} from '../../../../../store/rootDuck';
import ProductsGridItem from "./ProductGridItem";
import {ICart, IGuestCart} from "../../../cart/interfaces";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'grid',
      gap: 19,
      userSelect: 'none',
      gridTemplateColumns: 'minmax(300px,1fr) minmax(300px,1fr) minmax(300px,1fr);',
      [theme.breakpoints.down(1240)]: {
        gap: 4,
        gridTemplateColumns: 'minmax(235px,1fr) minmax(245px,1fr);',
      },
        [theme.breakpoints.down(515)]: {
            gap: 4,
            gridTemplateColumns: 'minmax(160px,1fr) minmax(160px,1fr);',
        },
      // [theme.breakpoints.down('sm')]: {
      //   gridTemplateColumns: 'repeat(2, 1fr)',
      // },
      // [theme.breakpoints.up('sm')]: {
      //   gridTemplateColumns: 'repeat(2, 1fr)',
      // },
      // [theme.breakpoints.up('md')]: {
      //   gridTemplateColumns: 'repeat(3, 1fr)',
      // },
      // [theme.breakpoints.up('lg')]: {
      //   gridTemplateColumns: 'repeat(3, 1fr)',
      // },
      // [theme.breakpoints.up('xl')]: {
      //   gridTemplateColumns: 'repeat(3, 1fr)',
      // },
      // // @ts-ignore
      // [theme.breakpoints.up('xxl')]: {
      //   gridTemplateColumns: 'repeat(5, 1fr)',
      // },
    },
  })
);

interface IProductsProps {
  data: IProduct[];
  cart?: ICart | null;
  guestCart?: IGuestCart | null;

  page?: number;
  perPage?: number;
  total?: number;
  categoryId: string;
  companyId: string;
  handleSetCountCart?: (type: 'dec' | 'inc', count: number, product: IProduct) => void;
  fetch?: (data: { page: number; perPage: number }) => void;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  handleCartDialog: any;
  isPaginator?: boolean;
  setProductCountLoading?: boolean;
}

const ProductsGrid: FC<IProductsProps> = (props) => {
    const {
      data,
      me,
    } = props;

    const fm = useFormatMessage();
    const classes = useStyles();
    const [heightImg, setHeightImg] = useState(335);
    const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
    const [createChatFetch, loadingCreated, createChat] = useCreateChat();

    // const newData =
    const getStock = useCallback(
      (product: IProduct) => {
        return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
      },
      [isBuyer, me]
    );

    const newChat = useCallback((userId?: string | number) => {
      createChatFetch({userId});
    }, []);

    return (
      <>
        <div
          className={classes.container}
        >
          {data.map(item => {
            return <ProductsGridItem key={item.id} item={item} {...props} />;
          })}
        </div>
        {/* {isPaginator && fetch && (
                    <div style={{display: 'flex', justifyContent: 'center'}} id='catalog-paginator'>
                        <TablePaginator
                            id={+categoryId}
                            page={page || 1}
                            perPage={perPage || 20}
                            total={total || 0}
                            realPerPage={data.length}
                            fetchRows={fetch}
                            companyId={companyId}
                            rowsPerPageOptions={[12, 36, 108]}
                            label={fm('PRODUCT.TABLE.PERPAGE')}
                        />
                    </div>
                )} */}
      </>
    );
  }
;

export default ProductsGrid;
