import React, { useMemo, useState } from 'react';
import {useSwipeable} from "react-swipeable";
import { Card, IconButton, makeStyles } from '@material-ui/core';
import ImageGalleryLib from 'react-image-gallery';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import ArrowNext from '@material-ui/icons/ArrowForwardIos';

import { useHistory } from 'react-router-dom';
import { IProduct } from '../../../../../interfaces/product';
import { API_DOMAIN } from '../../../../../constants';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import './image-gallery.css';

interface IProps {
  product: IProduct;
}

const useStyles = makeStyles(theme => ({
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    backgroundColor: 'transparent',
  },
  imgContainerSmall: {
    maxHeight: '250px',
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      maxHeight: '700px',
      minHeight: '700px',
      borderRadius: 0,
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1330px)']: {
      minHeight: '540px',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1000px)']: {
      minHeight: '450px',
    },
  },
  imgContainerFull: {
    maxHeight: '90vh',
    borderRadius: 0,
  },
  img: {
    maxWidth: 700,
    maxHeight: 700,
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      maxWidth: 200,
    },
  },
  galleryContainer: {
    width: '100%',
    marginBottom: '50px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    color: 'transparent',
  },
  goBack: {
    padding: '15px 25px',
    backgroundImage: 'linear-gradient(-50deg, #216214, #b2c9ad)',
    borderRadius: '0 35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    marginRight: 20,
  },
}));

const ImageGallery: React.FC<IProps> = ({ product }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlers = useSwipeable({
    onSwiped: event => event.event.stopPropagation(),
    trackMouse: true,
    trackTouch: true,
  });

  // images
  const images = useMemo(() => {
    if (product?.photos) {
      return product.photos
        .sort(a => (a.main ? -1 : 0))
        .map(el => ({
          original: el.big ? API_DOMAIN + el.big : toAbsoluteUrl('/images/placeholder.png'),
          thumbnail: el.small
            ? API_DOMAIN + el.small
            : toAbsoluteUrl('/images/placeholder.png'),
          renderItem: ({ original }: any) => (
            <Card
              className={`${classes.imgContainer} ${
                isFullScreen ? classes.imgContainerFull : classes.imgContainerSmall
              }`}
              style={{ height: '100%' }}
              onError={(e: any) => {
                e.target.src = toAbsoluteUrl('/images/placeholder.png');
              }}
            >
              <img
                alt=''
                className={classes.img}
                style={{ objectFit: 'contain' }}
                src={original}
              />
            </Card>
          ),
        }));
    }

    return null;
  }, [product, isFullScreen]);

  return (
    <div  {...handlers} className={classes.galleryContainer}>
      {/* <button onClick={() => history.push('/products/catalog')} type="button" className={classes.goBack}> */}
      {/*    <ArrowBack style={{ fontSize: 22, color: '#fff' }} /> */}
      {/* </button> */}
      {images && (
        <>
          {images.length > 0 ? (
            <ImageGalleryLib
              onScreenChange={boolean => setIsFullScreen(boolean)}
              items={images}
              useBrowserFullscreen
              showThumbnails={images.length > 1}
              showFullscreenButton={false}
              disableThumbnailScroll={false}
              showPlayButton={false}
              thumbnailPosition='left'
              infinite
              renderLeftNav={onClick => (
                <IconButton onClick={onClick} className={classes.arrow} style={{ left: -30 }}>
                  <ArrowBack style={{ fontSize: 30, color: '#216214' }} />
                </IconButton>
              )}
              renderRightNav={onClick => (
                <IconButton onClick={onClick} className={classes.arrow} style={{ right: -30 }}>
                  <ArrowNext style={{ fontSize: 30, color: '#216214' }} />
                </IconButton>
              )}
              onThumbnailError={(e: any) => {
                e.target.src = toAbsoluteUrl('/images/placeholder.png');
              }}
            />
          ) : (
            <Card
              className={`${classes.imgContainer} ${classes.imgContainerSmall}`}
              // elevation={0}
            >
              {/* <img */}
              {/*  alt='' */}
              {/*  className={classes.img} */}
              {/*  src={toAbsoluteUrl('/images/placeholder.png')} */}
              {/* /> */}
            </Card>
          )}
        </>
      )}
      {/* <Box style={{display: 'flex', flexDirection: 'column', gap: 7, marginLeft: 20}}> */}
      {/*  { */}
      {/*    product.parameter_values?.map(el => { */}
      {/*      if (el.parameter_name !== 'Marks') return */}
      {/*      return Array.isArray(el.value) && el.value.map(key => ( */}
      {/*          <ProductIcons nameView type={key} /> */}
      {/*      )) */}
      {/*    }) */}
      {/*  } */}
      {/* </Box> */}
    </div>
  );
};

export default ImageGallery;
