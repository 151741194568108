import React, { useCallback, useState } from 'react';
import { CheckInSystem } from './CheckInSystem';
import { EnterPassword } from './EnterPassword';
import { EnterConfirmCode, IUserData } from './EnterConfirmCode';
import { ForgotPassword } from './ForgotPassword';
import { Registration } from './Registration';
import { EmailSent } from './EmailSent';

export type TAuthTabs =
  | 'CheckInSystem'
  | 'EnterPassword'
  | 'ForgotPassword'
  | 'EnterConfirmCode'
  | 'Registration'
  | 'EmailSent';

export const AuthTabs = ({
  tabType,
  countryData,
  phone,
  values,
  userData,
  back,
  handleClose,
  isCart,
}: {
  tabType?: TAuthTabs;
  countryData?: any;
  phone?: string;
  values?: any;
  userData?: IUserData;
  back?: boolean;
  handleClose?: () => void;
  isCart?: boolean;
}) => {
  const [tab, setTab] = useState<TAuthTabs>(tabType || 'CheckInSystem');
  const [tabProps, setTabProps] = useState<any>({});
  const [successSend, setSuccessSend] = useState<boolean>(false);
  const authTabSwitcher = useCallback(() => {
    switch (tab) {
      case 'CheckInSystem':
        return (
          <CheckInSystem
            setSuccessSend={setSuccessSend}
            changeAuthTab={changeAuthTab}
            {...tabProps}
          />
        );
      case 'EnterPassword':
        return <EnterPassword changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'EnterConfirmCode':
        return (
          <EnterConfirmCode
            back={back}
            changeAuthTab={changeAuthTab}
            values2={values}
            countryData={countryData}
            phone={phone}
            userData={userData}
            handleClose={handleClose}
            successSend={successSend}
            setSuccessSend={setSuccessSend}
            isCart={isCart}
            {...tabProps}
          />
        );
      case 'ForgotPassword':
        return <ForgotPassword changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'Registration':
        return (
          <Registration
            changeAuthTab={changeAuthTab}
            setSuccessSend={setSuccessSend}
            {...tabProps}
          />
        );
      case 'EmailSent':
        return <EmailSent changeAuthTab={changeAuthTab} {...tabProps} />;
      default:
        return <></>;
    }
  }, [tab]);

  const changeAuthTab = useCallback((newTab: TAuthTabs, props: any = {}) => {
    setTab(newTab);
    setTabProps(props);
  }, []);

  return authTabSwitcher();
};
