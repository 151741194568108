import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProducts } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { IProductFilter } from '../../../../interfaces/product';

export const useLoadProductsList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [isLoad, setIsLoad] = useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchProducts = useCallback(
    async ({
      page,
      perPage,
      companyId,
      categoryId,
      search,
      filter,
      activity,
    }: {
      page: number;
      perPage: number;
      companyId?: number | null;
      categoryId?: number;
      search?: string;
      filter?: Partial<IProductFilter>;
      activity?: string;
    }) => {
      setLoading(true);
      setPage(1)
      setIsLoad(false);
      setProducts([]);
      getProducts({ page, perPage, companyId, categoryId, search, filter, activity })
        .then(res => {
          res.data.total_pages > 1 && setIsLoad(true);
          setProducts(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  const fetchLoadProducts = useCallback(
    async ({
      perPage,
      companyId,
      categoryId,
      search,
      filter,
      activity,
    }: {
      perPage: number;
      companyId?: number | null;
      categoryId?: number;
      search?: string;
      filter?: Partial<IProductFilter>;
      activity?: string;
    }) => {
      setLoading(true);
      getProducts({ page: page + 1, perPage, companyId, categoryId, search, filter, activity })
        .then(res => {
          const newPage = page + 1;
          if (res.data.total_pages > newPage || res.data.total_pages < newPage) {
            setPage(newPage);
            setIsLoad(true);
          }
          if (res.data.total_pages === newPage) {
            setPage(newPage);
            setIsLoad(false);
          }
          setProducts((state: any) => [...state, ...res.data.data]);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, page]
  );

  return { fetchProducts, fetchLoadProducts, products, isLoad, loading };
};
