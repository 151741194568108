import React, {useState} from 'react';
import {Box, Tooltip} from '@material-ui/core';
import {useIntl} from "react-intl";
import {toAbsoluteUrl} from '../../../_metronic';


const ProductIcons = ({type, nameView, fullWidth}: {
    type: string;
    nameView?: boolean,
    fullWidth?: boolean,
}) => {

    const intl = useIntl()

    switch (type) {
      case 'Sale':
        return (
          <Tooltip title={nameView ? '' : 'Sale'}>
            <Box
              style={{display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center', width: fullWidth ? '100%' : 45}}>
              <img src={toAbsoluteUrl('/images/wine-sale.svg')} alt="" width={45} height={45}/>
              {nameView && <p style={{
                textAlign: 'center',
                fontSize: 10,
                fontWeight: 'bold',
                color: '#000',
                textTransform: 'uppercase',
                transition: 'opacity 1s ease',
                opacity: 1,
                margin: 0
              }}>{intl.formatMessage({id: 'PRODUCT.VIEW.SALE'})}</p>}
            </Box>
          </Tooltip>
        );
        case 'WOOD MATURED':
            return (
                <Tooltip title={nameView ? '' : 'WOOD MATURED'}>
                    <Box
                        style={{display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center', width: fullWidth ? '100%' : 45}}>
                        <img src={toAbsoluteUrl('/images/wine-barrel.svg')} alt="" width={45} height={45}/>
                        {nameView && <p style={{
                            textAlign: 'center',
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: '#000',
                            textTransform: 'uppercase',
                            transition: 'opacity 1s ease',
                            opacity: 1,
                            margin: 0
                        }}>{intl.formatMessage({id: 'PRODUCT.VIEW.WOOD.MATURED'})}</p>}
                    </Box>
                </Tooltip>
            );
        case 'INVESTMENT WINE':
            return (
                <Tooltip title={nameView ? '' : 'INVESTMENT WINE'}>
                    <Box
                        style={{display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center', width: fullWidth ? '100%' : 45}}>
                        <img src={toAbsoluteUrl('/images/wine-invest.svg')} alt="" width={45} height={45}/>
                        {nameView && <p style={{
                            textAlign: 'center',
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: '#000',
                            textTransform: 'uppercase',
                            transition: 'opacity 1s ease',
                            opacity: 1,
                            margin: 0
                        }}>{intl.formatMessage({id: 'PRODUCT.VIEW.WOOD.NVESTMENT.WINE'})}</p>}
                    </Box>
                </Tooltip>
            );
        case 'NEW IN STOCK':
            return (
                <Tooltip title={nameView ? '' : 'NEW IN STOCK'}>
                    <Box
                        style={{display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center', width: fullWidth ? '100%' : 45}}>
                        <img src={toAbsoluteUrl('/images/wine-new.svg')} alt="" width={45} height={45}/>
                        {nameView && <p style={{
                            textAlign: 'center',
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: '#000',
                            textTransform: 'uppercase',
                            transition: 'opacity 1s ease',
                            opacity: 1,
                            margin: 0
                        }}>{intl.formatMessage({id: 'PRODUCT.VIEW.WOOD.NEW.IN.STOCK'})}</p>}
                    </Box>
                </Tooltip>
            );
        case 'RARE':
            return (
                <Tooltip title={nameView ? '' : 'RARE'}>
                    <Box
                        style={{display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center', width: fullWidth ? '100%' : 45}}>
                        <img src={toAbsoluteUrl('/images/wine-rare.svg')} alt="" width={45} height={45}/>
                        {nameView && <p style={{
                            textAlign: 'center',
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: '#000',
                            textTransform: 'uppercase',
                            transition: 'opacity 1s ease',
                            opacity: 1,
                            margin: 0
                        }}>{intl.formatMessage({id: 'PRODUCT.VIEW.RARE'})}</p>}
                    </Box>
                </Tooltip>
            );
        case 'ECO BIO':
            return (
                <Tooltip title={nameView ? '' : 'ECO BIO'}>
                    <Box
                        style={{display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center',width: fullWidth ? '100%' : 45}}>
                        <img src={toAbsoluteUrl('/images/wine-eco.svg')} alt="" width={45} height={45}/>
                        {nameView && <p style={{
                            textAlign: 'center',
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: '#000',
                            textTransform: 'uppercase',
                            transition: 'opacity 1s ease',
                            opacity: 1,
                            zIndex: 1000,
                            margin: 0
                        }}>{intl.formatMessage({id: 'PRODUCT.VIEW.WOOD.ECO.BIO'})}</p>}
                    </Box>
                </Tooltip>
            );
        default:
            return null
    }
};

export default ProductIcons;
