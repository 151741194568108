import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IProduct, IProductFilter } from '../../interfaces/product';
import { deleteProduct, getProducts } from '../../crud/product.crud';
import { getResponseMessage } from '../../utils/utils';
import {SortType} from "../../interfaces/productType";

const CLEAR_REQUEST = 'products-catalog/CLEAR_REQUEST';
const FETCH_REQUEST = 'products-catalog/FETCH_REQUEST';
const FETCH_SUCCESS = 'products-catalog/FETCH_SUCCESS';
const FETCH_FAIL = 'products-catalog/FETCH_FAIL';

const FETCH_REQUEST2 = 'products-catalog/FETCH_REQUEST2';
const FETCH_SUCCESS2 = 'products-catalog/FETCH_SUCCESS2';

const SET_EMPTY = 'products-catalog/SET_EMPTY';
const DEL_REQUEST = 'products-catalog/DEL_REQUEST';
const DEL_SUCCESS = 'products-catalog/DEL_SUCCESS';
const DEL_FAIL = 'products-catalog/DEL_FAIL';
const CLEAR_DEL = 'products-catalog/CLEAR_DEL';
const SET_FILTER = 'products-catalog/SET_FILTER';
const CLEAR_FILTER = 'products-catalog/CLEAR_FILTER';
const CLEAR_PRODUCTS = 'products-catalog/CLEAR_PRODUCTS';
const SET_VIEWTYPE = 'products-catalog/SET_VIEWTYPE';
const SET_CLUSTER_CENTER = 'products-catalog/SET_CLUSTER_CENTER';
const SET_SEARCH = 'products-catalog/SET_SEARCH';
const SET_CATEGORY_ID_ACTIVE = 'products-catalog/SET_CATEGORY_ID_ACTIVE';

export type ViewType = 'list' | 'grid' | 'table' | 'map';
export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  products: IProduct[] | undefined;
  productsFull: IProduct[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;
  delError: string | null;
  delSuccess: boolean;
  isSearching: boolean;
  filter: IProductFilter;
  viewType: ViewType;
  clusterCenter: any;
  categoryIdActive: number | null;
}

const defaultPaginatorProps = {
  page: 1,
  per_page: 12,
  total: 0,
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  products: undefined,
  productsFull: undefined,
  clusterCenter: null,
  loading: false,
  success: false,
  error: null,
  delError: null,
  delSuccess: false,
  isSearching: false,
  filter: {
    price_from: 0,
    price_to: 0,
    parameters: '',
  },
  viewType: 'list',
  categoryIdActive: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'products-catalog', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, products: [] };
      }

      case CLEAR_PRODUCTS: {
        return { ...state, products: undefined, filter: initialState.filter };
      }

      case CLEAR_REQUEST: {
        return { ...state, page: 1, per_page: 20 };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          products: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          products: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_REQUEST2: {
        return {
          ...state,
          productsFull: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS2: {
        const duplicateObjects = action.payload?.data?.filter(
          (item: IProduct, index: number) => {
            const hasValidLatLng =
              item.lat !== null &&
              item.lng !== null &&
              typeof item.lat !== 'undefined' &&
              typeof item.lng !== 'undefined';

            if (!hasValidLatLng) {
              return false; // Skip objects with null or undefined lat/lng
            }

            const isDuplicate =
              action.payload?.data?.findIndex(
                (otherItem: IProduct, otherIndex: number) =>
                  index !== otherIndex &&
                  otherItem.lat !== null &&
                  otherItem.lng !== null &&
                  item.lat === otherItem.lat &&
                  item.lng === otherItem.lng
              ) !== -1;

            return isDuplicate;
          }
        );

        const addNoiseToCoordinates = (lat: number, lng: number, noiseLevel: number) => {
          const latNoise = (Math.random() - 0.5) * noiseLevel;
          const lngNoise = (Math.random() - 0.5) * noiseLevel;
          const newLat = lat + latNoise;
          const newLng = lng + lngNoise;

          return { newLat, newLng };
        };

        const updatedItems: any = action.payload.data.map(product => {
          const { lat, lng, ...rest } = product;
          const { newLat, newLng } = addNoiseToCoordinates(lat || 0, lng || 0, 0.0001);
          const matchingX = duplicateObjects.find(itemX => itemX.id === product.id);

          if (matchingX) {
            return { ...rest, lat, lng, newLat, newLng };
          }
          return { ...rest, lat, lng, newLat: null, newLng: null };
        });

        return {
          ...state,
          // page: action.payload.page,
          // per_page: action.payload.per_page,
          // total: action.payload.total,
          productsFull: updatedItems,
          loading: false,
          success: true,
        };
      }

      case SET_FILTER: {
        const filter = {
          ...state.filter,
          ...action.payload,
        };

        return {
          ...state,
          ...defaultPaginatorProps,
          filter,
        };
      }

      case SET_CATEGORY_ID_ACTIVE: {
        
        return {
          ...state,
          categoryIdActive: action.payload,
        };
      }

      case CLEAR_FILTER: {
        return {
          ...state,
          filter: initialState.filter,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case DEL_FAIL: {
        return { ...state, delError: action.payload, delSuccess: false };
      }

      case DEL_SUCCESS: {
        return { ...state, delError: null, delSuccess: true };
      }

      case CLEAR_DEL: {
        return { ...state, delError: null, delSuccess: false };
      }

      case SET_VIEWTYPE: {
        return { ...state, viewType: action.payload };
      }

      case SET_SEARCH: {
        return { ...state, isSearching: action.payload };
      }

      case SET_CLUSTER_CENTER: {
        return {
          ...state,
          clusterCenter: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearRequest: () => createAction(CLEAR_REQUEST),
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string;
    categoryId?: number;
    companyId?: number | null;
    activity?: string;
    sortType?: SortType;
  }) => {
    return createAction(FETCH_REQUEST, payload);
  },
  fetchSuccess: (payload: IServerResponse<IProduct[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchRequest2: (payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string;
    categoryId?: number;
    companyId?: number | null;
    activity?: string;
    sortType?: SortType;
    for_map?: boolean;
    cache?: boolean
  }) => {
    return createAction(FETCH_REQUEST2, payload);
  },
  fetchSuccess2: (payload: IServerResponse<IProduct[]>) =>
    createAction(FETCH_SUCCESS2, payload),
  setCategoryIdActive: (payload: number) => createAction(SET_CATEGORY_ID_ACTIVE, payload),
  setEmpty: () => createAction(SET_EMPTY),
  clearProducts: () => createAction(CLEAR_PRODUCTS),
  setFilter: (payload: Partial<IProductFilter>) => createAction(SET_FILTER, payload),
  clearFilter: () => createAction(CLEAR_FILTER),
  delRequest: (payload: {
    id: number | undefined;
    page: number;
    perPage: number;
    filter: IProductFilter;
    categoryId?: number;
  }) => createAction(DEL_REQUEST, payload),
  delFail: (payload: string) => createAction(DEL_FAIL, payload),
  delSuccess: () => createAction(DEL_SUCCESS),
  clearDel: () => createAction(CLEAR_DEL),
  setViewType: (payload: ViewType) => createAction(SET_VIEWTYPE, payload),
  setSearch: (payload: boolean) => createAction(SET_SEARCH, payload),
  setClusterCenter: (payload: any) => createAction(SET_CLUSTER_CENTER, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string;
    categoryId?: number;
    companyId?: number | null;
    activity?: string;
    sortType?: SortType
  };
}) {
  try {
    if (typeof payload.categoryId === 'number') {
      yield put(actions.setCategoryIdActive(payload.categoryId));
    }
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProducts(payload)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}
function* fetchSaga2({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    filter: IProductFilter;
    search?: string;
    categoryId?: number;
    companyId?: number | null;
    activity?: string;
    sortType?: SortType;
    for_map?: boolean;
    cache?: boolean;
  };
}) {
  try {
    if (typeof payload.categoryId === 'number') {
      yield put(actions.setCategoryIdActive(payload.categoryId));
    }
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProducts(payload)
    );
    yield put(actions.fetchSuccess2(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* delSaga({ payload }: any) {
  try {
    yield call(() => deleteProduct(payload.id));
    yield put(actions.delSuccess());
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        filter: payload.filter,
        categoryId: payload.categoryId,
      })
    );
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST2, fetchSaga2);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DEL_REQUEST, delSaga);
}
