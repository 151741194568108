import { makeStyles, createStyles } from '@material-ui/core';

export const useStylesProducViewNoWine = makeStyles(theme =>
  createStyles({
    container: {
      marginTop: '0',
      padding: '0',
    },
    card: {
      backgroundColor: 'transparent',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      margin: '0 auto',
    },
    leftCol: {
      pointerEvents: 'auto',
      display: 'flex',
      flexDirection: 'column',
      marginRight: 30,
      minWidth: 350,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        minWidth: 200,
      },
    },
    rightCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      pointerEvents: 'auto',
      position: 'relative',
      minHeight: 250,
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: '#fff',
        marginTop: 15,
        paddingTop: 15,
        alignItems: 'center',
        width: '100%',
      },
    },
    header: {
      width: '100%',
      maxWidth: '1440px',
      margin: '0 auto',
    },
    name: {
      fontSize: 38,
      color: '#000',
      fontWeight: 300,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 28,
      },
    },
    priceTitle: {
      fontSize: 27,
      fontWeight: 'normal',
      color: '#ad1d11',
      textAlign: 'center',
      marginBottom: 0,
    },
    body: {
      padding: '10px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '1440px',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    addFav: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      alignItems: 'center',
      marginTop: 15,
    },
    favBtn: {
      backgroundColor: 'rgba(68, 24, 97, 0.65)',
      border: 'none',
      height: 50,
      width: 50,
      fontSize: 24,
      color: '#fff',

      transition: 'all .3s ease-out',
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
    favText: {
      fontSize: 24,
      height: 50,
      width: 50,
      fontWeight: 'bold',
      color: '#441861',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addBtn: {
     // width: 150,
      width: "max-content",
      minWidth:150,
      height: 50,
      border: 'none',
      color: '#fff',
      padding: '15px 25px',
      backgroundColor: '#441861',
      fontSize: 20,
      fontWeight: 'bold',
      marginTop: 25,
      '&:hover': {
        backgroundColor: 'rgba(68, 24, 97, 0.65)',
      },
    },
    description: {
      width: '70%',
      backgroundColor: '#216214',
      padding: 25,
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'column',
      gap: 20,
      minHeight: 320,
      marginBottom: 30,
      marginTop: 20,
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    degree: {
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'auto',
    },
    degreeText: {
      margin: 0,
      fontSize: 21,
      fontWeight: 300,
      color: '#000',
      fontFamily: 'MerriweatherSans',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    degreeBlock: {
      width: '70%',
      marginBottom: 30,
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    containerProduct: {
      width: '100%',
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 100,
      flexDirection: 'column',
      pointerEvents: 'none',
    },
    imgBackground: {
      width: '100%',
      position: 'absolute',
      objectFit: 'cover',
      zIndex: -100,
      height: 280,
      top: 320,
    },
    containerAddProduct: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 20,
    },
      wrapperPrice:{
      display:"flex",
      flexDirection:'column',
      alignItems:"center",
      justifyContent:'center'
      },
    containerPrice: {
      width: 150,
    },
    containerParams: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 300,
    },
    containerParam: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 15,
    },
    paramValue: {
      fontFamily: 'MerriweatherSans',
      fontWeight: 300,
      fontSize: 21,
      color: '#000000',
    },
    paramName: {
      fontFamily: 'MerriweatherSans',
      fontWeight: 400,
      fontSize: 14,
      color: '#757575',
    },
    productInfo: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      maxWidth: '1440px',
      padding: 10,
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
  })
);
