/* eslint-disable react/react-in-jsx-scope */
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Tooltip } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../_metronic";


export const GoogleMapComponent = withScriptjs(
  withGoogleMap((props: any) => {
    return (
      <>
        <GoogleMap
          defaultOptions={{ language: 'en' } as any}
          defaultZoom={11}
          defaultCenter={{ lat: props.product.lat || 0, lng: props.product.lng || 0 }}
          options={{fullscreenControl: false}}
        >
          {props.product.lat && props.product.lng && (
            <Tooltip title={props.product?.address_name}>
              <Marker
                position={{ lat: props.product.lat, lng: props.product.lng }}
                icon={toAbsoluteUrl('/images/marker.svg')}
              />
            </Tooltip>
          )}
        </GoogleMap>
      </>
    );})
);

