import React from 'react';
import {
  makeStyles,
  createStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  TableFooter,
  Tooltip,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import EditIcon from '@material-ui/icons/EditOutlined';
import TopTableCell from '../../other/Table/TopTableCell';
import { ButtonWithLoader } from '../../other/Buttons';
import TablePaginator from '../TablePaginator/TablePaginator';
import { ICompany } from '../../../interfaces/companies';
import { IUser } from '../../../interfaces/user';

interface IProps {
  companies: ICompany[];
  page: number;
  perPage: number;
  total: number;
  onConnectUser?: (companyId: any) => {} | any;
  me: IUser;
  fetch?: (data: { page: number; perPage: number }) => void;
  hideEdit?: boolean;
  textConnect?: string;
  hidePagination?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      marginBottom: 20,
    },
    editBtn: {
      marginRight: 5,
    },
  })
);

const CompaniesTable: React.FC<IProps> = ({
  companies,
  page,
  perPage,
  total,
  onConnectUser,
  me,
  fetch,
  hideEdit,
  textConnect,
  hidePagination,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const myCompany = me?.company;

  return (
    <div className={classes.table}>
      <Table aria-label='CompaniesTable'>
        <TableHead>
          <TableRow>
            <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.ID' })}</TopTableCell>
            <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.NAME' })}</TopTableCell>
            <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.BRAND' })}</TopTableCell>
            <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.INN' })}</TopTableCell>
            {/* <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.PHONE' })}</TopTableCell> */}
            {/* <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.SITE' })}</TopTableCell> */}
            {/* <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.ACTIONS' })}</TopTableCell> */}
            <TopTableCell> </TopTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map(company => (
            <TableRow key={company.id} selected={myCompany?.id === company.id}>
              <TableCell>{company.id || '-'}</TableCell>
              <TableCell>{company.name || '-'}</TableCell>
              <TableCell>{company.brand || '-'}</TableCell>
              <TableCell>{company.inn || '-'}</TableCell>
              {/* <TableCell>{company.phone_number || '-'}</TableCell> */}
              {/* <TableCell>{company.site || '-'}</TableCell> */}
              <TableCell style={{ paddingRight: 0 }}>
                <div style={{ minWidth: 145, display: 'flex', justifyContent: 'flex-end' }}>
                  {!hideEdit && (
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'PRODUCTS.TABLE.TOOLTIP.EDIT',
                      })}
                    >
                      <IconButton
                        className={classes.editBtn}
                        color='primary'
                        aria-label='previous'
                        onClick={() => history.push(`/companies/edit/${company.id}`)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {onConnectUser && (
                    <ButtonWithLoader onPress={() => onConnectUser(company)}>
                      {textConnect || intl.formatMessage({ id: 'COMMON.BUTTON.BIND' })}
                    </ButtonWithLoader>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {!hidePagination && (
          <TableFooter>
            <TableRow>
              <TablePaginator
                page={page}
                perPage={perPage}
                fetchRows={fetch}
                total={total}
                label={intl.formatMessage({ id: 'COMPANY.TABLE.TITLE' })}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
};

export default CompaniesTable;
