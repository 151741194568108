import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {useIntl} from "react-intl";

import {setLayoutSubheader} from "../../../utils/layout";
import {menuConfigItems} from "../../../constants/menuConfigItems";

const NavigationPage: React.FC<
  RouteComponentProps<{
    type?: string;
  }>
> = ({
  match: {
    params: { type },
  },
}) => {
  const intl = useIntl();

  if (type) {
    setLayoutSubheader({
      // @ts-ignore
      title: intl.formatMessage({ id: menuConfigItems[type].title }),
    });
  }

  return (
      <></>
  );
};


export default NavigationPage;
