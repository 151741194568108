import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import {
  Link as RouterLink,
  RouteComponentProps,
  useHistory,
  withRouter,
} from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  createStyles,
  IconButton,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import ProductListIcon from '@material-ui/icons/FormatListBulletedOutlined';
import { arrayMove, SortableHandle } from 'react-sortable-hoc';
import TablePaginator from '../../../components/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import StatusIndicator from '../../../components/styledComponents/StatusIndicator';
import Preloader from '../../../components/ui/Preloader/Preloader';

import { IAppState } from '../../../store/rootDuck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { actions as productForDaysActions } from '../../../store/ducks/product-for-days.duck';
import { actions as productActions } from '../../../store/ducks/product.duck';
import { actions as usersActions } from '../../../store/ducks/users.duck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import { actions as supplyActions } from '../../../store/ducks/supply.duck';
import homeStyles from '../homeStyles';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';

import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { ICompany } from '../../../interfaces/companies';
import { UserRoles } from '../../../interfaces/user';
import CompanyBind from '../companies/CompanyBind';
import { needBindCompany } from '../../../utils/user';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { thousands } from '../../../utils/utils';
import { IProduct } from '../../../interfaces/product';
import { getProductImage } from './utils/getProductImage';
import { toAbsoluteUrl } from '../../../../_metronic';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { ButtonTheme } from '../../../components/ui/Buttons/ButtonWithLoader';
import { API_DOMAIN } from '../../../constants';
import TableNewCategories from './components/TableNewCategories';
import TableNewProducts from './components/TableNewProducts';
import { getFio, getRole } from '../users/users/utils';
import { UserListType } from '../users/constants';
import TableNewVendors from './components/TableNewVendors';
import TableNewOrdes from './components/TableNewOrdes';
import { actions as orderActions } from '../../../store/ducks/orders.duck';
import { orderTypeList } from '../orders/constatns';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import TableNewCompanies from './components/TableNewCompanies';
import { Modal } from '../../../components/other/Modals';

const getStatusString = (type: string, selectedOrderStatuses: string[]): string => {
  if (type === 'full') {
    if (selectedOrderStatuses.length > 0) {
      return selectedOrderStatuses.toString();
    }
    return orderTypeList.toString();
  }
  if (type === 'abandoned') return 'cart';
  return '';
};

const useStyles = makeStyles(theme =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    cardConteiner: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      [theme.breakpoints.down(1360)]: {
        flexDirection: 'column',
      },
    },
    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: 20,
      marginRight: 20,
      minWidth: 650,
    },
    cardTitle: {
      marginLeft: theme.spacing(4),
      fontSize: 14,
      // fontFamily: 'Roboto Slab',
      fontWeight: 500,
      color: '#441861',
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
      cursor: 'pointer',
    },
    countReviews: {
      fontSize: 14,
      // fontFamily: 'Roboto Slab',
      fontWeight: 400,
      color: '#5D78FFFF',
    },
    textInput: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 450,
      alignItems: 'center',
    },
    closeButton: {
      // borderRadius: '25px 0 25px 0',
      backgroundColor: '#441861',
      color: 'white',
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 16,
      marginBottom: 12,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
    okButton: {
      // borderRadius: '25px 0 25px 0',
      backgroundColor: '#216214',
      color: 'white',
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 16,
      marginBottom: 12,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#216214',
      },
    },
  })
);

const DashboardList: React.FC<TPropsFromRedux & RouteComponentProps<{ type: string }>> = ({
  orders,
  fetchOrders,
  products,
  fetchUsers,
  users,
  filter,
  categories,
  fetchCategories,
  fetchCompanies,
  clearDelCompany,
  companies,
  delCompanyLoading,
  delCompanySuccess,
  delCompanyError,
  delCompany,
  loading,
  fetch,
  page,
  perPage,
  total,
  delProduct,
  clearDel,
  deleteError,
  setEmpty,
  me,
  fetchMe,
  loadingMe,
  successMe,
  clearMe,
  clearProducts,
  pageForDays,
  perPageForDays,
  productsForDays,
  forDays,
  fetchProductsForDays,
  setForDays,

  addProductLoading,
  addProductSuccess,
  addProductError,
  addProductToCart,
  cartCompanyId,
  clearParameters,
  supplyCompanyId,
  addProductSupplyError,
  addProductSupplySuccess,
  addProductToSupply,
  clearAddProduct,
  fetchAccord,
  supplyData,
  isAuthorized,
  match: {
    params: { type },
  },
}) => {
  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const fm = useFormatMessage();
  const history = useHistory();
  const role: any = getRole(type as UserListType);
  const { enqueueSnackbar } = useSnackbar();
  const isRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [cartProductId, setCartProductId] = useState<number>(-1);
  // const [isCartAlertOpen, setCartAlertOpen] = useState(false);
  const [userCompany, setUserCompany] = useState<null | ICompany>();
  const [forDaysValue, setForDaysValue] = useState<null | number>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(undefined);
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState<string[]>([]);

  const [supplyProductId, setSupplyProductId] = useState<number>(-1);
  const [isSupplyAlertOpen, setSupplyAlertOpen] = useState(false);

  const [open, setOpen] = React.useState(false);

  const isProductsNew = useMemo(() => type === 'new', [type]);

  const title = useMemo(() => {
    if (isProductsNew) {
      return 'PRODUCT.ALL.NEW_PRODUCTS';
    }
    return 'MENU.DASHBOARD';
  }, [isProductsNew]);

  const productList = useMemo(() => (isProductsNew ? productsForDays : products), [
    products,
    productsForDays,
    type,
  ]);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { forDays: forDaysValue },
    enableReinitialize: true,
    onSubmit: values => {
      if (values.forDays) {
        setForDays({ page: pageForDays, perPage: perPageForDays, forDays: +values.forDays });
      }
    },
  });

  useLayoutEffect(() => {
    isRoleVendor && fetchAccord();
  }, [isRoleVendor]);

  useEffect(() => {
    fetchMe();
    clearParameters();
    return () => {
      clearMe();
      clearProducts();
      clearAddProduct();
    };
  }, []);

  useEffect(() => {
    if (me && !userCompany) {
      setUserCompany(me.company);
    }
  }, [successMe]);

  useEffect(() => {
    setForDaysValue(forDays);
  }, [forDays]);

  useEffect(() => {
    if (isProductsNew) {
      fetchProductsForDays({ page: pageForDays, perPage: perPageForDays });
    }
    if (userCompany !== undefined && me) {
      if (me.is_admin || isManager) {
        fetch({ page, perPage });
      } else if (userCompany) {
        const companyId = userCompany.id;
        fetch({ page, perPage, companyId });
      } else {
        setEmpty();
      }
    }
  }, [fetch, userCompany, me, fetchProductsForDays, isProductsNew]);

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: 'error' });
      setAlertOpen(false);
      clearDel();
    }
    return () => {
      clearDel();
    };
  }, [deleteError, clearDel, enqueueSnackbar]);

  useEffect(() => {
    fetchCategories({ page, perPage });
  }, [fetchCategories]);

  useEffect(() => {
    fetchCompanies({ page, perPage });
  }, [fetchCompanies]);

  useEffect(() => {
    fetchUsers({ page, perPage, role: 'ROLE_VENDOR', filter });
  }, [role, filter]);
  useEffect(() => {
    const statuses = getStatusString(type, selectedOrderStatuses);
    fetchOrders({ statuses, payment_status: '', page: 1, perPage: 20 });
  }, [type, selectedOrderStatuses]);

  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      // setCartAlertOpen(false);
      setCartProductId(-1);
      clearAddProduct();
    },
  });

  useCrudSnackbar({
    success: addProductSupplySuccess,
    error: addProductSupplyError,
    successMessage: fm('SUPPLY.ADD.SUCCESS'),
    errorMessage: `${addProductSupplyError}`,
    afterSuccess: () => {
      fetchAccord();
    },
  });
  useCrudSnackbar({
    success: delCompanySuccess,
    error: delCompanyError,
    clear: clearDelCompany,
    successMessage: intl.formatMessage({ id: 'COMPANY.DELETE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${delCompanyError}`,
    afterSuccessOrError: () => {
      clearDelCompany();
      setOpen(false);
    },
    afterSuccess: () => fetchCompanies({ page, perPage }),
  });

  // setLayoutSubheader({
  //   title:  '',
  //   breadcrumb: [
  //     {
  //       title: fm('MENU.PRODUCTS.CATALOG'),
  //       root: true,
  //       page: `products/dashboard`,
  //       translate: 'MENU.PRODUCTS.CATALOG',
  //     },
  //   ],
  //   isSubmenu: true,
  //   back: true,
  //   show: true,
  // });
  // setLayoutFooter({ show: true });

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (userCompany !== undefined && me) {
      if (me.is_admin || isManager) {
        delProduct({ page, perPage, id: deleteId });
      } else if (userCompany) {
        const companyId = userCompany.id;
        delProduct({ page, perPage, id: deleteId, companyId });
      }
    }
    if (isProductsNew) {
      fetchProductsForDays({ page: pageForDays, perPage: perPageForDays });
    }
  }, [deleteId, delProduct, perPage, page, me, userCompany]);

  const editAction = useCallback(
    (item, isReviews?: boolean) => {
      history.push({ pathname: `/products/edit/${item.id}`, state: { isReviews } });
    },
    [history]
  );

  const viewAction = useCallback(
    item => {
      history.push(`/viewproduct/${item.id}`);
    },
    [history]
  );
  const handleDelCompany = useCallback(
    (id: number) => {
      delCompany(id);
    },
    [delCompany]
  );

  const handleAddToCart = useCallback(
    (item: IProduct) => {
      if (!item.id) return;

      // const isTheSameCompany = cartCompanyId === item.company?.id;

      // if (isTheSameCompany) {
      addProductToCart({ product_id: item.id, count: 1 });
      // } else {
      //   setCartProductId(item.id);
      //   setCartAlertOpen(true);
      // }
    },
    [cartCompanyId]
  );

  const handleAddToSupply = useCallback(
    (item: IProduct) => {
      if (!item.id) return;
      const documentProduct = supplyData?.find(doc => doc.product.id === item.id);
      addProductToSupply({
        product_id: item.id,
        count: documentProduct ? documentProduct.amount + 1 : 1,
      });
    },
    [supplyCompanyId, supplyData]
  );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading) {
      addProductToCart({
        product_id: cartProductId,
        count: 1,
        newCart: true,
      });
    }
  }, [cartProductId, addProductToCart]);

  const addSupplyProductAction = useCallback(() => {
    if (!addProductLoading) {
      addProductToSupply({
        product_id: supplyProductId,
        count: 1,
        newCart: true,
      });
    }
  }, [supplyProductId, addProductToSupply]);
  const DragHandle = SortableHandle(() => <span style={{ cursor: 'move' }}>::::</span>);

  const goToCreateProduct = useCallback(() => {
    if (me?.is_admin || isManager) {
      history.push(`/products/create`);
    } else {
      history.push(`/products/create/company/${me?.company?.id}/${me?.company?.name}`);
    }
  }, [history, me, isManager]);
  const pushAction = useCallback(
    (parentId?: string) => {
      const id = parentId || selectedCategoryId;
      history.push(`${id ? `/categories/new/${id}` : '/categories/new'}`, { isTree: true });
    },
    [selectedCategoryId, history]
  );

  if (!me || loadingMe || !productList || loading) return <Preloader />;

  if (needBindCompany(me)) {
    return <CompanyBind />;
  }

  return (
    <>
      {/* <AlertDialog */}
      {/*  open={isAlertOpen} */}
      {/*  message={intl.formatMessage({ id: 'PRODUCT.DELETE.TEXT' })} */}
      {/*  okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })} */}
      {/*  cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })} */}
      {/*  handleClose={() => { */}
      {/*    clearDel(); */}
      {/*    setAlertOpen(false); */}
      {/*  }} */}
      {/*  handleAgree={() => deleteAction()} */}
      {/* /> */}
      <Modal
        open={isAlertOpen}
        onClose={() => {
          clearDel();
          setAlertOpen(false);
        }}
        content={intl.formatMessage({ id: 'PRODUCT.DELETE.TEXT' })}
        actions={
          delCompanyLoading
            ? undefined
            : [
                {
                  title: intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' }),
                  onClick: () => {
                    clearDel();
                    setAlertOpen(false);
                  },
                  className: classes.closeButton,
                },
                {
                  title: intl.formatMessage({ id: 'COMMON.BUTTON.DELETE' }),
                  onClick: () => deleteAction(),
                  className: classes.okButton,
                },
              ]
        }
      />

      {/* <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => addCartProductAction()}
      />

      <AlertDialog
        open={isSupplyAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setSupplyAlertOpen(false);
        }}
        handleAgree={() => addSupplyProductAction()}
      /> */}
      <div style={{ maxWidth: 1440, width: '100%', margin: '0 auto' }}>
        <ButtonWithLoader
          style={{ marginBottom: 20 }}
          theme={ButtonTheme.PRIMARY}
          onPress={() => goToCreateProduct()}
        >
          {intl.formatMessage({ id: 'PRODUCT.BUTTON.NEW.ADD' })}
        </ButtonWithLoader>
        {(isAdmin || isManager) && (
          <ButtonWithLoader
            style={{ marginBottom: 20 }}
            theme={ButtonTheme.SECONDARY}
            onPress={() => history.push('/companies/new')}
          >
            {intl.formatMessage({ id: 'SUBMENU.COMPANIES.NEW.ADD' })}
          </ButtonWithLoader>
        )}
        {(isAdmin || isManager) && (
          <ButtonWithLoader
            style={{ marginBottom: 20 }}
            theme={ButtonTheme.SECONDARY}
            onPress={() => pushAction()}
          >
            {intl.formatMessage({ id: 'CATEGORIES.BUTTON.NEW.ADD' })}
          </ButtonWithLoader>
        )}

        {isProductsNew && (
          <form onSubmit={handleSubmit} autoComplete='off' className={classes.textInput}>
            <TextField
              type='number'
              label={intl.formatMessage({ id: 'PRODUCT.FOR.DAYS' })}
              margin='normal'
              name='forDays'
              value={values.forDays}
              variant='outlined'
              style={{ marginRight: 30 }}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.forDays && errors.forDays}
              error={Boolean(touched.forDays && errors.forDays)}
            />
            <Button
              color='primary'
              type='submit'
              variant='contained'
              style={{ height: '100%' }}
            >
              {intl.formatMessage({ id: 'COMPANY.TABLE.BUTTON.EDIT' })}
            </Button>
          </form>
        )}

        <Row>
          <Col>
            {!productList || !productList.length ? (
              <Typography className={classes.empty} component='h5' variant='h5'>
                {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
              </Typography>
            ) : (
              <div>
                <div className={classes.cardConteiner}>
                  {isAdmin || isManager ? (
                    <TableNewProducts
                      addProductLoading={addProductLoading}
                      productList={productList}
                      handleAddToCart={handleAddToCart}
                      handleDeleteDialog={handleDeleteDialog}
                      editAction={editAction}
                      me={me}
                      isAuthorized={isAuthorized}
                    />
                  ) : (
                    <TableNewOrdes
                      type={type}
                      orders={orders}
                      smallSendMsgBtn
                      selectedOrderStatuses={selectedOrderStatuses}
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      width: '50%',
                    }}
                  >
                    {isRoleVendor ? (
                      // <TableNewCategories categories={categories} />
                      <TableNewProducts
                        addProductLoading={addProductLoading}
                        productList={productList}
                        handleAddToCart={handleAddToCart}
                        handleDeleteDialog={handleDeleteDialog}
                        editAction={editAction}
                        me={me}
                        isAuthorized={isAuthorized}
                      />
                    ) : (
                      <TableNewOrdes
                        type={type}
                        orders={orders}
                        smallSendMsgBtn
                        selectedOrderStatuses={selectedOrderStatuses}
                      />
                    )}
                    {!isRoleVendor && (
                      <TableNewCompanies
                        companies={companies}
                        me={me}
                        delCompanyLoading={delCompanyLoading}
                        delCompany={handleDelCompany}
                        open={open}
                        setOpen={setOpen}
                      />
                    )}
                  </div>
                </div>
                {isAdmin ||
                  (isManager && <TableNewVendors me={me} type={type} users={users} />)}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    products: state.products.products,
    loading: state.products.loading,
    page: state.products.page,
    perPage: state.products.per_page,
    total: state.products.total,
    deleteError: state.products.delError,
    categories: state.categories.categories,
    companies: state.companies.companies,

    me: state.profile.me,
    loadingMe: state.profile.loading,
    successMe: state.profile.success,

    addProductLoading: state.cart.addProductLoading,
    addProductSuccess: state.cart.addProductSuccess,
    addProductError: state.cart.addProductError,
    cartCompanyId: state.cart.companyId,
    isAdmin: state.auth.user?.is_admin,

    pageForDays: state.productForDays.page,
    perPageForDays: state.productForDays.per_page,
    productsForDays: state.productForDays.productsForDays,
    forDays: state.productForDays.forDays,

    addProductSupplyError: state.supply.addProductError,
    addProductSupplySuccess: state.supply.addProductSuccess,
    supplyData: state.supply.accord?.document_products,
    supplyCompanyId: state.supply.companyId,
    delCompanySuccess: state.companies.delCompanySuccess,
    delCompanyLoading: state.companies.delCompanyLoading,
    delCompanyError: state.companies.delCompanyError,

    users: state.users.users,
    filter: state.users.filter,
    isAuthorized: state.auth.user != null,

    orders: state.orders.orders,
  }),
  {
    fetch: productActions.fetchRequest,
    fetchUsers: usersActions.fetchRequest,
    fetchCategories: categoriesActions.fetchRootRequest,
    delProduct: productActions.delRequest,
    clearDel: productActions.clearDel,
    setEmpty: productActions.setEmpty,
    clearProducts: productActions.clearProducts,
    clearParameters: productTypesActions.clearParams,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    addProductToCart: cartActions.addProductRequest,

    fetchProductsForDays: productForDaysActions.fetchRequest,
    setForDays: productForDaysActions.setForDays,

    clearAddProduct: supplyActions.clearAddProduct,
    fetchAccord: supplyActions.fetchAccord,
    addProductToSupply: supplyActions.addProductRequest,
    fetchCompanies: companiesActions.fetchRequest,
    delCompany: companiesActions.delCompanyRequest,
    clearDelCompany: companiesActions.clearDelCompany,
    fetchOrders: orderActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(DashboardList));
