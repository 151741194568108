export enum UserListType {
  BUYER = 'buyers',
  MANAGER = 'managers',
  VENDOR = 'vendors',
}

export const ADMIN_ROLE = 'ROLE_ADMIN';
export const BUYER_ROLE = 'ROLE_BUYER';
export const MANAGER_ROLE = 'ROLE_MANAGER';
export const VENDOR_ROLE = 'ROLE_VENDOR';
export const ALL_ROLE = 'ROLE_ADMIN,ROLE_MANAGER,ROLE_VENDOR,ROLE_USER';
