import axios from 'axios';
import {
  GET_COMPANIES_URL,
  CREATE_COMPANY_URL,
  SEARCH_COMPANY_URL,
  editCompanyUrl,
  getCompanyUrl,
  delCompanyUrl,
  ADD_COMPANY_URL,
} from '../constants';

import { ICompanyEditProps, ICompanyAddProps } from '../interfaces/companies';

export function getCompanies(
  page: number,
  perPage: number,
  roles?: string,
  noRoles?: boolean,
  inn?: string
) {
  return axios.get(
    `${GET_COMPANIES_URL}?page=${page}&per_page=${perPage}${roles ? `&roles=${roles}` : ''}${
      noRoles ? `&no_roles=true` : ''
    }${inn ? `&inn=${inn}` : ''}`
  );
}

export function getCompany(id: number) {
  return axios.get(getCompanyUrl(id));
}

export function createCompany(data: ICompanyAddProps) {
  return axios.post(CREATE_COMPANY_URL, data);
}

export const searchCompanies = (inn: string) => axios.get(`${SEARCH_COMPANY_URL}?inn=${inn}`);

export function editCompany(id: number, data: ICompanyEditProps) {
  return axios.put(editCompanyUrl(id), data);
}

export function delCompany(id: number) {
  return axios.delete(delCompanyUrl(id));
}

export function uploadLogo(id: string, formData: FormData) {
  return axios.post(`/api/company/${id}/upload_logo`, formData);
}

export function deleteLogo(id: string) {
  return axios.delete(`/api/company/${id}/delete_logo`);
}

export const addCompanyByInn = (inn: string, role?: string) => {
  return axios.post(`${ADD_COMPANY_URL}?inn=${inn}&role=${role}`);
};

export const searchSantions = (inn: string) => {
  return axios.get(`/api/companies/sanctions?inn=${inn}`);
};
