import { IOrderType } from '../../../interfaces/order';

export enum ListType {
  ABANDONED = 'abandoned',
  FULL = 'full',
}

export const orderTypeList: string[] = [
  'new',
  'formation',
  'ready_to_delivery',
  'in_processing',
  'canceled',
  'completed',
];

export const orderTypeList2: string[] = [
  'paid',
  'not_paid',
];

export const getOrderTypeWithTranslates = (
  translates: {
    [type: string]: string;
  },
  isFull: boolean
): IOrderType[] => {
  const orderTypes: IOrderType[] =
    Object.keys(translates).length > 2
      ? orderTypeList.map(item => ({
          type: item,
          translate: translates[item],
        }))
      : orderTypeList2.map(item => ({
          type: item,
          translate: translates[item],
        }));

  if (isFull) {
    orderTypes.unshift({ type: 'cart', translate: translates.cart });
  }
  return orderTypes;
};
