import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  createStyles,
  IconButton,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import SendIcon from '@material-ui/icons/Send';

import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import AlertDialog from '../../../../components/other/Dialog/AlertDialog';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { ColorButton } from '../../../../components/other/Buttons';
import { IAppState } from '../../../../store/rootDuck';
import { actions as orderActions } from '../../../../store/ducks/orders.duck';
import { actions as messsageActions } from '../../../../store/ducks/messages.duck';
import homeStyles from '../../../../constants/homeStyles';
import { UserRoles } from '../../../../interfaces/user';
import { IOrder } from '../../../../interfaces/order';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
// import { useStyles } from '../hooks/useStyles';
import { useFormatMessage } from '../../../../hooks';
import { useCreateChat } from '../../chats/chatsPage/hooks/useCreateChat';
import { getOrderTypeWithTranslates, ListType, orderTypeList } from '../../orders/constatns';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import { ButtonTheme } from '../../../../components/ui/Buttons/ButtonWithLoader';
import { Modal } from '../../../../components/other/Modals';

const getStatusString = (type: string, selectedOrderStatuses: string[]): string => {
  if (type === 'full') {
    if (selectedOrderStatuses.length > 0) {
      return selectedOrderStatuses.toString();
    }
    return orderTypeList.toString();
  }
  if (type === 'abandoned') return 'cart';
  return '';
};

const useStyles = makeStyles(theme =>
  createStyles({
    buttons: {
      marginRight: theme.spacing(3),
    },

    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: 20,
      marginRight: 20,
      minWidth: 650,
    },
    cardTitle: {
      marginLeft: theme.spacing(4),
      fontSize: 14,
      fontWeight: 500,
      color: '#441861',
    },

    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
      cursor: 'pointer',
    },
    typeList: {
      backgroundColor: 'white',
      maxWidth: 200,
    },
    viewButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.primary.dark,
      textTransform: 'none',
      boxShadow: 'none',
      borderRadius: 2,
      padding: '10px 35px',
      fontWeight: 700,
      background: '#EBECF0',

      '&:hover': {
        boxShadow: 'none',
      },
    },
    iconViewButton: {
      marginLeft: 10,
    },
    closeButton: {
      // borderRadius: '25px 0 25px 0',
      backgroundColor: '#441861',
      color: 'white',
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 16,
      marginBottom: 12,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
    okButton: {
      // borderRadius: '25px 0 25px 0',
      backgroundColor: '#216214',
      color: 'white',
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 16,
      marginBottom: 12,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#216214',
      },
    },
    head: {
      fontWeight: 'bold',
      fontSize: 14,
      paddingBottom: 17,
      paddingTop: 17,
    },
    cell: {
      padding: 13,
    }
  })
);

export enum ViewMode {
  VIEW = 'view',
  EDIT = 'edit',
}

const TableNewOrdes: React.FC<
  TPropsFromRedux & {
    type: string;
    userId?: number;
    smallSendMsgBtn?: boolean;
    orders: IOrder[] | undefined;
    selectedOrderStatuses: string[];
  }
> = ({
  loading,
  orders,
  selectedOrderStatuses,
  page,
  perPage,
  total,
  delOrder,
  delError,
  clearErrors,
  editStatus,
  userRole,
  clearMessages,
  meLoading,
  fetchMe,
  clearMe,
  me,
  type,
  userId,
  smallSendMsgBtn,
}) => {
  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const fm = useFormatMessage();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const [showModalMessages, setShowModalMessages] = useState(false);
  const [currentResponse, setCurrentResponse] = useState<IOrder | null>(null);
  const [createChatFetch, loadingCreated, createChat] = useCreateChat();

  // const [selectedOrderStatuses, setSelectedOrderStatuses] = useState<string[]>([]);

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      delOrder({
        page,
        perPage,
        id: deleteId,
        payment_status: '',
        statuses: getStatusString(type, selectedOrderStatuses),
      });
    }
  }, [deleteId, page, perPage, type, delOrder]);

  const editAction = useCallback(
    (item, mode) => {
      history.push(`/orders/${item.id}/${mode}`);
    },
    [history]
  );

  const selectAction = useCallback(
    id => {
      setSelectedId(id);
    },
    [setSelectedId]
  );
  useEffect(() => {
    if (delError) {
      enqueueSnackbar(delError, { variant: 'error' });
      setAlertOpen(false);
      clearErrors();
    }
    return () => {
      clearErrors();
    };
  }, [delError, clearErrors, setAlertOpen, enqueueSnackbar]);

  // useEffect(() => {
  //     const statuses = getStatusString(type, selectedOrderStatuses);
  //     fetch({statuses, page: 1, perPage: 20, userId});
  // }, [type, userId, selectedOrderStatuses]);

  useEffect(() => {
    if (!me) fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  const translates: { [type: string]: string } = {
    cart: intl.formatMessage({ id: 'ORDER.TYPE.CART' }),
    new: intl.formatMessage({ id: 'ORDER.TYPE.NEW' }),
    formation: intl.formatMessage({ id: 'ORDER.TYPE.FORMATION' }),
    ready_to_delivery: intl.formatMessage({ id: 'ORDER.TYPE.DELIVERY' }),
    payed: intl.formatMessage({ id: 'ORDER.TYPE.PAYED' }),
    canceled: intl.formatMessage({ id: 'ORDER.TYPE.CANCELED' }),
    completed: intl.formatMessage({ id: 'ORDER.TYPE.COMPLETED' }),
    in_processing: intl.formatMessage({ id: 'ORDER.TYPE.PROCCESS' }),
  };
  const orderTypes = getOrderTypeWithTranslates(translates, type === ListType.ABANDONED);

  const isBuyer = useMemo(() => userRole?.[0] === UserRoles.ROLE_BUYER, [userRole]);
  const isVendor = useMemo(() => userRole?.[0] === UserRoles.ROLE_VENDOR, [userRole]);
  const isAdmin = useMemo(() => userRole?.[0] === UserRoles.ROLE_ADMIN, [userRole]);
  const indexNum = isVendor ? 7 : 5;

  const handlerShowMessages = useCallback((item: IOrder) => {
    setCurrentResponse(item);
    setShowModalMessages(true);
  }, []);

  const closeMessages = useCallback(() => {
    setShowModalMessages(false);
    setCurrentResponse(null);
    clearMessages();
  }, []);

  const orderStatusOptions = useMemo(() => {
    return orderTypes.map(item => ({
      label: item.translate,
      value: item.type,
    }));
  }, [orderTypes]);

  const newChat = useCallback((companyId?: string | number, userId?: string | number) => {
    if (companyId) {
      createChatFetch({ companyId });
    }
    createChatFetch({ userId });
  }, []);

  const newUserChat = useCallback((userId?: string | number) => {
    createChatFetch({ userId });
  }, []);

  if (meLoading || loadingCreated) return <Preloader />;

  return (
    <>
      <Card className={classes.card}>
        <text className={classes.cardTitle}>
          {' '}
          {isVendor
            ? intl.formatMessage({ id: 'SUBMENU.LASTORDERS' }).toUpperCase()
            : intl.formatMessage({ id: 'SUBMENU.NEWORDERS' }).toUpperCase()}
        </text>
        <CardContent className={homeClasses.tableContainer}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'ORDER.TABLE.ID' })}
                </SHeadTableCell>
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'ORDER.TABLE.DATE' })}
                </SHeadTableCell>
                {isVendor && (
                  <SHeadTableCell className={classes.head}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.PRODUCT_AMOUNT' })}
                  </SHeadTableCell>
                )}
                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'ORDER.TABLE.SUM_ORDER' })}
                </SHeadTableCell>
                {isVendor && (
                  <SHeadTableCell className={classes.head}>
                    {intl.formatMessage({ id: 'ORDER.TABLE.SUM_PAY' })}
                  </SHeadTableCell>
                )}

                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'ORDER.TABLE.STATUS' })}
                </SHeadTableCell>
                <SHeadTableCell>
                  {intl.formatMessage({ id: 'ORDER.TABLE.STATUS.PAYMENT' })}
                </SHeadTableCell>

                <SHeadTableCell className={classes.head}>
                  {intl.formatMessage({ id: 'ORDER.TABLE.ACTIONS' })}
                </SHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.map((item, index) =>
                index < indexNum ? (
                  <TableRow key={item.id}>
                    <TableCell className={classes.cell}>{item.id || '-'}</TableCell>
                    <TableCell className={classes.cell}>
                      {item.created_at ? moment(item.created_at).format('DD.MM.YY') : '-'}
                    </TableCell>
                    {isVendor && (
                      <TableCell className={classes.cell}>{item.goods_num || '-'}</TableCell>
                    )}
                    <TableCell className={classes.cell}>{item.order_summ || '-'}</TableCell>
                    {isVendor && (
                      <TableCell className={classes.cell}>{item.summ || '-'}</TableCell>
                    )}

                    <TableCell className={classes.cell}>
                      {selectedId !== item.id ? (
                        <ColorButton
                          color='#216214'
                          onClick={() => selectAction(item.id)}
                          disable={Boolean(isBuyer)}
                        >
                          {translates[item.status]}
                        </ColorButton>
                      ) : (
                        <TextField
                          select
                          margin='normal'
                          value={item.status}
                          onChange={event => {
                            editStatus({
                              id: item.id,
                              page,
                              perPage,
                              statuses: getStatusString(type, selectedOrderStatuses),
                              payment_status: '',
                              data: { status: event.target.value },
                              userId,
                            });
                            selectAction(-1);
                          }}
                          variant='outlined'
                          className={classes.typeList}
                          size='small'
                        >
                          {orderTypes &&
                            orderTypes.map(option => (
                              <MenuItem key={option.type} value={option.type}>
                                {option.translate}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    </TableCell>
                    <TableCell>
                      {fm(
                        item.payment_status === 'paid'
                          ? 'ORDER.PAYMENT.STATUS.PAID'
                          : 'ORDER.PAYMENT.STATUS.NOT_PAID'
                      )}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {isBuyer && (
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'PRODUCTS.TABLE.TOOLTIP.VIEW',
                          })}
                        >
                          <IconButton
                            onClick={() => {
                              editAction(item, ViewMode.VIEW);
                            }}
                          >
                            <VisibilityIcon color='primary' />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!isBuyer && (
                        <>
                          <Tooltip
                            title={intl.formatMessage({
                              id: 'PRODUCTS.TABLE.TOOLTIP.EDIT',
                            })}
                          >
                            <IconButton
                              onClick={() => {
                                editAction(item, ViewMode.EDIT);
                              }}
                            >
                              <EditIcon color='primary' />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title={intl.formatMessage({
                              id: 'PRODUCTS.TABLE.TOOLTIP.DELETE',
                            })}
                          >
                            <IconButton
                              onClick={() => {
                                handleDeleteDialog(item.id);
                              }}
                            >
                              <DeleteIcon color='error' />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </TableBody>
            {/* <TableFooter>
                                                    <TableRow>
                                                        <TablePaginator
                                                            statuses={getStatusString(type, selectedOrderStatuses)}
                                                            page={page}
                                                            realPerPage={orders.length}
                                                            perPage={perPage}
                                                            total={total}
                                                            fetchRows={fetch}
                                                            label={intl.formatMessage({ id: 'ORDER.TABLE.PERPAGE' })}
                                                        />
                                                    </TableRow>
                                                </TableFooter> */}
          </Table>
        </CardContent>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonWithLoader
            style={{ marginBottom: 25 }}
            theme={ButtonTheme.SECONDARY}
            onPress={() => history.push('/orders/list/full')}
          >
            {intl.formatMessage({ id: 'CATEGORIES.BUTTON.VIEW.ALL' })}
          </ButtonWithLoader>
        </div>
      </Card>
      <Modal
        open={isAlertOpen}
        onClose={loading ? () => {} : () => setAlertOpen(false)}
        loading={loading}
        content={loading ? undefined : fm('ORDER.DELETE.TEXT')}
        actions={
          loading
            ? undefined
            : [
                {
                  title: fm('COMMON.BUTTON.CANCEL'),
                  onClick: () => {
                    clearErrors();
                    setAlertOpen(false);
                  },
                  className: classes.closeButton,
                },
                {
                  title: fm('COMMON.BUTTON.DELETE'),
                  onClick: () => deleteAction(),
                  className: classes.okButton,
                },
              ]
        }
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    meLoading: state.profile.loading,
    loading: state.orders.loading,
    page: state.orders.page,
    perPage: state.orders.per_page,
    total: state.orders.total,
    delError: state.orders.delError,
    userRole: state.auth.user?.roles,
    me: state.profile.me,
  }),
  {
    delOrder: orderActions.delRequest,
    clearErrors: orderActions.clearEdit,
    editStatus: orderActions.editStatusRequest,

    clearMessages: messsageActions.clear,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TableNewOrdes);
