import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IProduct, IReview } from '../../interfaces/product';
import {
  createProduct,
  editProduct,
  getProductById,
  getProducts,
  delPhoto,
  deleteProduct,
  setMainPhoto,
  editStock,
} from '../../crud/product.crud';
import { getResponseMessage } from '../../utils/utils';

const FETCH_REQUEST = 'products/FETCH_REQUEST';
const FETCH_SUCCESS = 'products/FETCH_SUCCESS';
const FETCH_FAIL = 'products/FETCH_FAIL';
const SET_EMPTY = 'products/SET_EMPTY';

const FETCH_BY_ID_REQUEST = 'products/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'products/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'products/FETCH_BY_ID_FAIL';

const DEL_REQUEST = 'products/DEL_REQUEST';
const DEL_SUCCESS = 'products/DEL_SUCCESS';
const DEL_FAIL = 'products/DEL_FAIL';
const CLEAR_DEL = 'products/CLEAR_DEL';
const CLEAR_PRODUCTS = 'products/CLEAR_PRODUCTS';

const CLEAR_EDIT = 'products/CLEAR_EDIT';
const ADD_REQUEST = 'products/ADD_REQUEST';
const EDIT_REQUEST = 'products/EDIT_REQUEST';
const EDIT_SUCCESS = 'products/EDIT_SUCCESS';
const EDIT_FAIL = 'products/EDIT_FAIL';
const CLEAR_PRODUCT = 'products/CLEAR_PRODUCT';

const CLEAR_UPLOAD_PHOTO = 'products/CLEAR_UPLOAD_PHOTO';
const UPLOAD_PHOTO_REQUEST = 'products/UPLOAD_PHOTO_REQUEST';
const UPLOAD_PHOTO_SUCCESS = 'products/UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_FAIL = 'products/UPLOAD_PHOTO_FAIL';

const CLEAR_DEL_PHOTO = 'products/CLEAR_DEL_PHOTO';
const DEL_PHOTO_REQUEST = 'products//DEL_PHOTO_REQUEST';
const DEL_PHOTO_SUCCESS = 'products/DEL_PHOTO_SUCCESS';
const DEL_PHOTO_FAIL = 'products/DEL_PHOTO_FAIL';

const CLEAR_SET_MAIN_PHOTO = 'products/CLEAR_SET_MAIN_PHOTO';
const SET_MAIN_PHOTO_REQUEST = 'products/SET_MAIN_PHOTO_REQUEST';
const SET_MAIN_PHOTO_SUCCESS = 'products/SET_MAIN_PHOTO_SUCCESS';
const SET_MAIN_PHOTO_FAIL = 'products/SET_MAIN_PHOTO_FAIL';

const SET_PRODUCT_REVIEWS = 'products/SET_PRODUCT_REVIEWS';
const ADD_SUCCESS = 'products/ADD_SUCCESS';
const CLEAR_PAGINATION = 'products/CLEAR_PAGINATION';

const EDIT_STOCK_REQUEST = 'products/EDIT_STOCK_REQUEST';
const EDIT_STOCK_FAIL = 'products/EDIT_STOCK_FAIL';
const ADD_PRODUCT_NAME = 'products/ADD_PRODUCT_NAME';
const EDIT_STOCK_SUCCESS = 'products/EDIT_STOCK_SUCCESS';
const EDIT_OPEN_MAP = 'products/EDIT_OPEN_MAP';
const CLEAR_BY_ID = 'products/CLEAR_BY_ID';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  products: IProduct[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;
  productsName: any;

  product: IProduct | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  delError: string | null;
  delSuccess: boolean;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  photoLoading: boolean;
  photoSuccess: boolean;
  photoError: string | null;

  delPhotoLoading: boolean;
  delPhotoSuccess: boolean;
  delPhotoError: string | null;

  setMainPhotoLoading: boolean;
  setMainPhotoSuccess: boolean;
  setMainPhotoError: string | null;

  reviewsProduct: {
    total: number;
    rating: number | null;
    reviews: IReview[];
    meAdd: boolean;
  } | null;

  addSuccess: boolean;
  addProductId: number | undefined;

  editStockLoading: boolean;
  editStockSuccess: boolean;
  editStockError: string | null;

  openMap: boolean;
}

const defaultPaginatorProps = {
  page: 1,
  per_page: 10,
  total: 0,
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  products: undefined,
  loading: false,
  success: false,
  error: null,
  productsName: null,

  product: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  delError: null,
  delSuccess: false,

  editLoading: false,
  editSuccess: false,
  editError: null,

  photoLoading: false,
  photoSuccess: false,
  photoError: null,

  delPhotoLoading: false,
  delPhotoSuccess: false,
  delPhotoError: null,

  setMainPhotoLoading: false,
  setMainPhotoSuccess: false,
  setMainPhotoError: null,

  reviewsProduct: null,
  addSuccess: false,
  addProductId: undefined,

  editStockLoading: false,
  editStockSuccess: false,
  editStockError: null,

  openMap: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'products', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, products: [] };
      }

      case CLEAR_PRODUCTS: {
        return { ...state, products: undefined };
      }

      case CLEAR_PRODUCT: {
        return { ...state, product: undefined };
      }

      case CLEAR_PAGINATION: {
        return { ...state, ...defaultPaginatorProps };
      }

      case EDIT_OPEN_MAP: {
        return { ...state, openMap: action.payload };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          products: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          products: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          product: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_BY_ID: {
        return {
          ...state,
          byIdLoading: false,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case ADD_PRODUCT_NAME: {
        return {
          ...state,
          productsName: action.payload,
        };
      }
      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          addSuccess: false,
          editStockLoading: false,
          editStockSuccess: false,
          editStockError: null,
          product: undefined,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return {
          ...state,
          editLoading: true,
          editSuccess: false,
          editError: null,
          addSuccess: false,
          addProductId: undefined,
        };
      }

      case ADD_SUCCESS: {
        return {
          ...state,
          editLoading: false,
          editSuccess: true,
          addProductId: action.payload.id,
          addSuccess: true,
          product: undefined,
        };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, product: action.payload };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case DEL_FAIL: {
        return { ...state, delError: action.payload, delSuccess: false };
      }

      case DEL_SUCCESS: {
        return { ...state, delError: null, delSuccess: true };
      }

      case CLEAR_DEL: {
        return { ...state, delError: null, delSuccess: false };
      }

      case CLEAR_UPLOAD_PHOTO: {
        return { ...state, photoLoading: false, photoSuccess: false, photoError: null };
      }

      case UPLOAD_PHOTO_REQUEST: {
        return {
          ...state,
          photoLoading: true,
          photoSuccess: false,
          photoError: null,
        };
      }

      case UPLOAD_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          photoLoading: false,
          photoSuccess: true,
        };
      }

      case UPLOAD_PHOTO_FAIL: {
        return { ...state, photoLoading: false, photoError: action.payload };
      }

      case CLEAR_DEL_PHOTO: {
        return {
          ...state,
          delPhotoLoading: false,
          delPhotoSuccess: false,
          delPhotoError: null,
        };
      }

      case DEL_PHOTO_REQUEST: {
        return {
          ...state,
          delPhotoLoading: true,
          delPhotoSuccess: false,
          delPhotoError: null,
        };
      }

      case DEL_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          delPhotoLoading: false,
          delPhotoSuccess: true,
        };
      }

      case DEL_PHOTO_FAIL: {
        return {
          ...state,
          delPhotoLoading: false,
          delPhotoError: action.payload,
        };
      }

      case CLEAR_SET_MAIN_PHOTO: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_REQUEST: {
        return {
          ...state,
          setMainPhotoLoading: true,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: true,
        };
      }

      case SET_MAIN_PHOTO_FAIL: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoError: action.payload,
        };
      }

      case SET_PRODUCT_REVIEWS: {
        return {
          ...state,
          reviewsProduct: {
            rating: action.payload.rating,
            reviews: action.payload.data,
            total: action.payload.total,
            meAdd: action.payload.me_add,
          },
        };
      }

      case EDIT_STOCK_REQUEST: {
        return {
          ...state,
          editStockLoading: true,
          editStockSuccess: false,
          editStockError: null,
        };
      }

      case EDIT_STOCK_SUCCESS: {
        return {
          ...state,
          editStockLoading: false,
          editStockSuccess: true,
          product: undefined,
        };
      }

      case EDIT_STOCK_FAIL: {
        return { ...state, editStockLoading: false, editStockError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    companyId?: number;
    categoryId?: number;
  }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IProduct[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  setEmpty: () => createAction(SET_EMPTY),
  clearProducts: () => createAction(CLEAR_PRODUCTS),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IProduct>) => {
    return createAction(FETCH_BY_ID_SUCCESS, payload);
  },
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),
  clearById: () => createAction(CLEAR_BY_ID),
  delRequest: (payload: {
    id: number | undefined;
    categoryId?: number;
    page: number;
    perPage: number;
    companyId?: number;
  }) => createAction(DEL_REQUEST, payload),
  delFail: (payload: string) => createAction(DEL_FAIL, payload),
  delSuccess: () => createAction(DEL_SUCCESS),
  clearDel: () => createAction(CLEAR_DEL),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { data: FormData; stockQuantity: number }) =>
    createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: FormData }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: IProduct) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
  clearProduct: () => createAction(CLEAR_PRODUCT),

  clearPhotoRequest: () => createAction(CLEAR_UPLOAD_PHOTO),
  uploadPhotoRequest: (payload: { modificationId: number; files: FormData }) =>
    createAction(UPLOAD_PHOTO_REQUEST, payload),
  uploadPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(UPLOAD_PHOTO_SUCCESS, payload),
  uploadPhotoFail: (payload: string) => createAction(UPLOAD_PHOTO_FAIL, payload),

  clearDelPhotoRequest: () => createAction(CLEAR_DEL_PHOTO),
  delPhotoRequest: (payload: number) => createAction(DEL_PHOTO_REQUEST, payload),
  delPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(DEL_PHOTO_SUCCESS, payload),
  delPhotoFail: (payload: string) => createAction(DEL_PHOTO_FAIL, payload),

  clearSetMainPhotoRequest: () => createAction(CLEAR_SET_MAIN_PHOTO),
  setMainPhotoRequest: (payload: number) => createAction(SET_MAIN_PHOTO_REQUEST, payload),
  setMainPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(SET_MAIN_PHOTO_SUCCESS, payload),
  setMainPhotoFail: (payload: string) => createAction(SET_MAIN_PHOTO_FAIL, payload),

  setProductReviews: (payload: {
    rating: number | null;
    data: IReview[];
    total: number;
    me_add: boolean;
  }) => createAction(SET_PRODUCT_REVIEWS, payload),
  addSuccess: (payload: IProduct) => createAction(ADD_SUCCESS, payload),
  clearPagination: () => createAction(CLEAR_PAGINATION),
  editStockRequest: (payload: { product_id: number; body: any }) =>
    createAction(EDIT_STOCK_REQUEST, payload),
  editStockSuccess: (payload: IProduct) => createAction(EDIT_STOCK_SUCCESS, payload),
  editStockFail: (payload: string) => createAction(EDIT_STOCK_FAIL, payload),
  addProductName: (payload: any) => createAction(ADD_PRODUCT_NAME, payload),
  editOpenMap: (payload: boolean) => createAction(EDIT_OPEN_MAP, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: { page: number; perPage: number; companyId?: number; categoryId?: number };
}) {
  try {
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProducts(payload)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      getProductById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* addSaga({ payload }: { payload: { data: FormData; stockQuantity: number } }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      createProduct(payload.data)
    );
    yield put(actions.addSuccess(data.data));
    yield put(
      actions.editStockRequest({
        product_id: Number(data.data.id),
        body: {
          stockQuantity: payload.stockQuantity,
        },
      })
    );
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: FormData } }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      editProduct(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* delPhotoSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() => delPhoto(payload));
    yield put(actions.delPhotoSuccess(data));
  } catch (e) {
    yield put(actions.delPhotoFail(getResponseMessage(e)));
  }
}

function* setMainPhotoSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      setMainPhoto(payload)
    );
    yield put(actions.setMainPhotoSuccess(data));
  } catch (e) {
    yield put(actions.setMainPhotoFail(getResponseMessage(e)));
  }
}

function* delSaga({
  payload,
}: {
  payload: {
    id: number | undefined;
    categoryId?: number;
    page: number;
    perPage: number;
    companyId?: number;
  };
}) {
  try {
    yield call(() => deleteProduct(payload.id));
    yield put(actions.delSuccess());
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        companyId: payload.companyId,
        categoryId: payload.categoryId,
      })
    );
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e)));
  }
}

function* editStockSaga({ payload }: { payload: { product_id: number; body: FormData } }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      editStock(payload.product_id, payload.body)
    );
    yield put(actions.editStockSuccess(data.data));
  } catch (e) {
    yield put(actions.editStockFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(
    FETCH_BY_ID_REQUEST,
    fetchByIdSaga
  );
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.delPhotoRequest>>(
    DEL_PHOTO_REQUEST,
    delPhotoSaga
  );
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DEL_REQUEST, delSaga);
  yield takeLatest<ReturnType<typeof actions.setMainPhotoRequest>>(
    SET_MAIN_PHOTO_REQUEST,
    setMainPhotoSaga
  );
  yield takeLatest<ReturnType<typeof actions.editStockRequest>>(
    EDIT_STOCK_REQUEST,
    editStockSaga
  );
}
