import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { menuConfigItems } from "../../../constants/menuConfigItems";

interface IProps {
  type?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  btn: {
    color: '#441861',
    fontWeight: 700,
    fontSize: "1rem",
    display: 'block',
    padding: '0.6rem 1rem',
    transition: 'all 0.3s ease',
   // borderRadius: '21px 0',
    '&:hover': {
      backgroundColor: 'rgba(68, 24, 97, 0.25)',
    },
  },
  wrap: {
    padding: '0px 0.25rem',
  }
});

const ButtonNavigation: React.FC<IProps> = ({ type}) => {
  if (!type) {
    return null;
  }
  // @ts-ignore
  const items = [...menuConfigItems[type].submenu];
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
        {items?.map((item, index) => {
          return (
            <div className={classes.wrap}>
              <Link to={`/${item.page || ''}`} className={classes.btn}>
                  {intl.formatMessage({ id: item.title })}
              </Link>
            </div>
          );
        })}
    </div>
  );
};


export default ButtonNavigation;
