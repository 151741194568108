import {UserRoles} from '../interfaces/user';

export const needBindCompany = (user: any | undefined) => {
  if (!user) {
    return false;
  }

  return user.is_admin || user.roles.includes(UserRoles.ROLE_MANAGER)
    ? false
    : user &&
        !user.roles.includes(UserRoles.ROLE_BUYER) &&
        (!user.company || user.manager_status === 'In moderation');
};
