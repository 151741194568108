import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { useIntl } from 'react-intl';
import TermDialog from './TermDialog';

interface IAlertDialog extends DialogProps {
  handleAgree: any;
  handleClose: any;
  cancelText?: string;
}

const useStyles = makeStyles(() => ({
  cookieText: {
    width: '266px',
    fontFamily: 'Merriweather-Light',
    margin: 0,
    fontSize: 24,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000',
  },
  cookieTextBold: {
    fontWeight: 'bold',
    color: '#78dc78',
    cursor: 'pointer',
  },
  dialogContent: {
    display: 'flex',
    // width: '326px',
    position: 'fixed',
    borderRadius: 0,
    // right: '0',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 50,
  },
}));

const VerificationAge: React.FC<IAlertDialog> = ({ open, handleClose, handleAgree }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    !open && setOpenDialog(false);
  }, [open]);

  const text = intl.formatMessage({ id: 'COOKIE2.TEXT' });

  const targetWords: any = {
    cz: 'Zásadách zpracování osobních údajů',
    de: 'Datenschutzerklärung',
    en: 'Privacy Policy',
  };

  const targetWord = targetWords[intl.locale] || targetWords.en;

  const parts = text.split(targetWord);

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => handleClose(reason)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        classes={{ paper: classes.dialogContent }}
        disableEscapeKeyDown
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <p
              style={{
                fontSize: 60,
                color: '#000',
                fontWeight: 300,
                marginBottom: 15,
                lineHeight: 1,
                fontFamily: 'Merriweather-Light',
              }}
            >
              <span style={{ fontSize: 50, fontFamily: 'Merriweather-Light' }}>1</span>8+
            </p>
            {parts.map((part, index) => (
              <span className={classes.cookieText} key={index}>
                {part}
                {index < parts.length - 1 && (
                  <span style={{ color: '#441861', fontFamily: 'Merriweather-Light' }}>
                    {targetWord}
                  </span>
                )}
              </span>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ borderRadius: 0 }}
            fullWidth
            onClick={handleAgree}
            variant='contained'
            color='primary'
            autoFocus
          >
            <span style={{ padding: 0, margin: 0, fontSize: 24, color: '#fff' }}>
              {intl.formatMessage({ id: 'COOKIE2.OK' })}
            </span>
          </Button>
        </DialogActions>
      </Dialog>
      <TermDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        contentType='cookie'
      />
    </>
  );
};

export default VerificationAge;
