import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useFormikEnterConfirmCode = (
  countryData: any,
  phone: string,
  makeRequest: any,
) => {
  const intl = useIntl();

  return useFormik({
    initialValues: { code: '' },
    validationSchema: Yup.object().shape({
      code: Yup.string().required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
      ),
    }),
    onSubmit: submitValues => {
      makeRequest(
        `/api/users/code_login?country_code=${countryData.code}&phoneNumbers=${phone}&code=${submitValues.code}`
      );
    },
  });
};
