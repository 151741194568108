import React, { useCallback, useState, useEffect } from 'react';
import { Card } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ICategoryTreeTable } from '../interfaces';
import { ICategoryItem } from '../../categories/interfaces';
import { IAppState } from '../../../../store/rootDuck';
import { getProductImage } from '../utils/getProductImage';
import { actions } from '../../../../store/ducks/product.duck';
import { useLoadProductsList } from '../hooks/useLoadProductsList';
import { IProduct } from '../../../../interfaces/product';

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      margin: 0,
      paddingLeft: 0,
      listStyle: 'none',
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color)`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    cardCategory: {
      marginBottom: theme.spacing(3),
      maxWidth: '235px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        margin: 0
      },
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 0,
      gap: 8,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    content: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: 'max-content',
      padding: 8,
      cursor: 'pointer',
      position: 'relative',
      transition: '.25s ease-out',
      borderRadius: '18px 0 18px 0',
      [theme.breakpoints.down('lg')]: {
        padding: 4,
      },
    },
    labelContainerChildren: {
      maxHeight: '100%',
      overflow: 'hidden',
    },
    labelChildren: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: 'max-content',
      maxWidth: '225px',
      padding: 8,
      cursor: 'pointer',
      marginLeft: 20,
      transition: '.25s ease-out',
      borderRadius: '18px 0 18px 0',
    },
    labelIcon: {},
    labelTitle: {
      fontWeight: 400,
      fontFamily: 'MerriweatherSans',
      flexGrow: 1,
      fontSize: 14,
      color: '#441861',
      textTransform: 'uppercase',
      width: 'max-content',
      maxWidth: '225px',
    },
    labelText: {
      fontWeight: 400,
      flexGrow: 1,
      fontSize: 14,
      color: '#000',
      textTransform: 'uppercase',
      maxWidth: '190px',
    },
    activeItem: {
      backgroundColor: 'rgba(33, 98, 20, 0.15)',
      borderRadius: 0,
      width: 'max-content',
      maxWidth: '225px',
    },
    hide: {
      height: 0,
    },
    marks: {
      padding: 13,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
    markBody: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 8,
      marginLeft: '0 !important',
    },
    marksTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#441861',
      textTransform: 'uppercase',
    },
    cardBtn1: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: 13,
      fontWeight: 900,
      textTransform: 'uppercase',
      color: '#441861',
    },
    cardBtn2: {
      padding: '8px 25px',
      backgroundColor: '#216214',
      fontSize: 13,
      color: '#fff',
      border: 'none',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    edit: {
      backgroundColor: '#216214',
      borderRadius: 0,
      padding: '5px 30px',
      color: '#fff',
      fontSize: 14,
      fontWeight: 900,
      width: '100%',
      border: 'none',
    },
  })
);

type TestProps = {
  select: (id: number) => void;
  categories: ICategoryItem[];
  selectChildrenId: number | null;
  setSelectChildrenId: (id: number) => void;
  locale: string;
  selectedCategoryId: number | null;
};

export const StyledTreeItem = ({
  categories,
  select,
  setSelectChildrenId,
  selectChildrenId,
  locale,
  selectedCategoryId,
}: TestProps) => {
  const classes = useTreeItemStyles();

  return (
    <ul className={classes.root}>
      {categories?.map(item => (
        <StyledTreeSubItem
          key={item.id}
          category={item}
          select={select}
          selectChildrenId={selectChildrenId}
          setSelectChildrenId={setSelectChildrenId}
          locale={locale}
          selectedCategoryId={selectedCategoryId}
        />
      ))}
    </ul>
  );
};

type TestPropsItem = {
  category: ICategoryItem;
  select: (id: number) => void;
  selectChildrenId: number | null;
  setSelectChildrenId: (id: number) => void;
  locale: string;
  selectedCategoryId: number | null;
};
export const StyledTreeSubItem = ({
  category,
  select,
  setSelectChildrenId,
  selectChildrenId,
  locale,
  selectedCategoryId,
}: TestPropsItem) => {
  const classes = useTreeItemStyles();
  const [selectChild, setSelectChild] = useState<boolean>(false);
  const [rootCategory, setRootCategory] = useState<boolean>(false);
  // @ts-ignore
  // const nameLang = locale === 'ru' ? category.name : category.names[`name_${locale}`];

  useEffect(() => {
    const localSelect = localStorage.getItem(`${category.id}selectChild`);
    localSelect && setSelectChild(localSelect === 'true');
  }, []);

  useEffect(() => {
    selectedCategoryId !== selectChildrenId &&
      selectedCategoryId === category?.id &&
      setSelectChildrenId(selectedCategoryId);
  }, [selectedCategoryId]);

  const handleItem = (id: number) => {
    select(id);
    category && category.id && setSelectChildrenId(category.id);
  };
  const onClickHandler = () => {
    localStorage.setItem(`${category.id}selectChild`, JSON.stringify(!selectChild));
    setSelectChild(!selectChild);
  };

  return (
    <>
      {category.products_num !== undefined && category.products_num > 0 ? (
        <li style={{ marginLeft: '20px' }}>
          <div
            onClick={() => {
              onClickHandler();
              category?.id && handleItem(category.id);
            }}
            className={clsx(classes.labelRoot, {
              [classes.activeItem]: category.id === selectChildrenId,
            })}
          >
            <div style={{ position: 'absolute', left: -20 }}>
              {Boolean(category.children?.length) &&
                (selectChild ? (
                  <ArrowDropDownIcon style={{ color: '#216214' }} />
                ) : (
                  <ArrowRightIcon style={{ color: '#216214' }} />
                ))}
            </div>
            <Typography
              variant='body2'
              // className={!nameLang ? classes.fontNoLang : classes.labelText}
              className={classes.labelText}
              // style={{ color: category.id === selectChildrenId ? '#d01c1f' : '#000000' }}
            >
              {category.name}
            </Typography>
          </div>
          {/* поменять на !selectChild чтоб категории показывать  в развернутом виде */}
          {selectChild && category.children?.length ? (
            <StyledTreeItem
              categories={category.children}
              select={select}
              selectChildrenId={selectChildrenId}
              setSelectChildrenId={setSelectChildrenId}
              locale={locale}
              selectedCategoryId={selectedCategoryId}
            />
          ) : null}
        </li>
      ) : null}
    </>
  );
};

const FilterCategoriesTree: React.FC<ICategoryTreeTable> = ({
  categories,
  searchProducts,
  resetProducts,
  filter,
  setSearch,
  setSelectedCategoryId,
  selectedCategoryId,
  activity,
  vendorCompanyId,
}) => {
  const intl = useIntl();
  const [selectChildrenId, setSelectChildrenId] = useState<number>(0);
  const {
    fetchProducts,
    loading,
    isLoad,
    products: productsList,
    fetchLoadProducts,
  } = useLoadProductsList();
  const { viewType } = useSelector(
    ({ productsCatalog: { viewType } }: IAppState) => ({ viewType }),
    shallowEqual
  );
  const classes = useTreeItemStyles();
  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    setSearch(true);
    selectedCategoryId &&
      searchProducts({
        page: 1,
        perPage: 12,
        filter,
        categoryId: selectedCategoryId,
        companyId: vendorCompanyId,
        activity,
      });
    selectedCategoryId &&
      fetchProducts({
        page: 1,
        perPage: 12,
        filter,
        categoryId: selectedCategoryId,
        companyId: vendorCompanyId,
        activity,
      });
  }, [selectedCategoryId, filter, activity, vendorCompanyId]);

  const loadProducts = useCallback(() => {
    selectedCategoryId &&
      fetchLoadProducts({
        perPage: 12,
        filter,
        categoryId: selectedCategoryId,
        companyId: vendorCompanyId,
        activity,
      });
  }, [selectedCategoryId, filter, activity, vendorCompanyId, fetchLoadProducts]);

  const select = (id: number) => {
    setSelectedCategoryId(id);
  };

  useEffect(() => {
    fetch();
  }, [selectedCategoryId]);

  const clear = () => {
    resetProducts();
    if (filter.parameters.length === 0 && !filter.price_from && !filter.price_to) {
      setSearch(false);
    }
    setSelectedCategoryId(null);
  };

  const getProduct = (id?: number) => {
    dispatch(actions.editOpenMap(true));
    dispatch(actions.fetchByIdRequest(Number(id)));
  };

  return (
    <Card className={classes.cardCategory}>
      <div>
        <Typography variant='h5' className={classes.labelTitle} style={{ marginBottom: 15 }}>
          {intl.formatMessage({ id: 'CATEGORIES.TITLE' })}
        </Typography>
        <ul className={classes.root}>
          <StyledTreeItem
            categories={categories}
            select={select}
            setSelectChildrenId={setSelectChildrenId}
            selectChildrenId={selectChildrenId}
            locale={intl.locale}
            selectedCategoryId={selectedCategoryId}
          />
        </ul>

        {viewType === 'map' && !!(selectChildrenId || selectedCategoryId) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              marginTop: 30,
              cursor: 'pointer',
            }}
          >
            {selectedCategoryId || selectChildrenId
              ? productsList?.map((el: IProduct) => (
                  <div
                    onClick={() => getProduct(el.id)}
                    style={{ display: 'flex', gap: 30, alignItems: 'center',maxHeight: 130 ,minHeight:100}}
                  >
                    {getProductImage(el) ? (
                      <img
                        style={{ width: 30 }}
                        src={getProductImage(el)}
                        alt=''
                      />
                    ) : (
                      <div style={{ width: 30, height: 100 }} />
                    )}

                    <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                      <Typography style={{ width: 150, fontWeight: 300, fontSize: 15,}}>
                        {el.name}
                      </Typography>
                      <Typography style={{ color: '#ad1d11' }}>CZK {el.price}</Typography>
                    </div>
                  </div>
                ))
              : null}

            {isLoad && selectedCategoryId && (
              <button
                className={classes.edit}
                type='button'
                onClick={event => {
                  event.stopPropagation();
                  loadProducts();
                }}
                disabled={loading}
                color='primary'
              >
                {intl.formatMessage({ id: 'LOAD.MORE' })}
              </button>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default FilterCategoriesTree;
