import React, { useMemo, useState } from 'react';
import {useSwipeable} from "react-swipeable";
import { Card, IconButton, makeStyles } from '@material-ui/core';
import ImageGalleryLib from 'react-image-gallery';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import ArrowNext from '@material-ui/icons/ArrowForwardIos';
import { API_DOMAIN } from '../../../../../constants';
import { IProduct } from '../../../../../interfaces/product';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import './style.css';


interface IProps {
  product: IProduct;
}

const useStyles = makeStyles(theme => ({
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    backgroundColor: 'transparent',
  },
  imgContainerSmall: {
    maxHeight: '400px',
    maxWidth: 700,

    [theme.breakpoints.up('md')]: {
      maxHeight: '700px',
    },
  },
  imgContainerFull: {
    maxHeight: '85vh',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
    backgroundColor: 'transparent',
  },
  galleryContainer: {
    width: '100%',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    color: 'transparent',
  },
  imgOne: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
    backgroundColor: 'transparent',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:500px)']: {
      width: 'initial',
    },
  },
}));

const ImageGalleryNoWine: React.FC<IProps> = ({ product }) => {
  const classes = useStyles();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handlers = useSwipeable({
    onSwiped: event => event.event.stopPropagation(),
    trackMouse: true,
    trackTouch: true,
  });
  // images
  const images = useMemo(() => {
    if (product?.photos) {
      return product.photos
        .sort(a => (a.main ? -1 : 0))
        .map(el => ({
          original: el.big ? API_DOMAIN + el.big : toAbsoluteUrl('/images/placeholder.png'),
          thumbnail: el.small
            ? API_DOMAIN + el.small
            : toAbsoluteUrl('/images/placeholder.png'),
          renderItem: ({ original }: any) => (
            <Card
              className={`${classes.imgContainer} ${
                isFullScreen ? classes.imgContainerFull : classes.imgContainerSmall
              }`}
              style={{ height: 700 }}
            >
              <img
                alt=''
                className={classes.img}
                style={{ objectFit: 'contain' }}
                src={original}
              />
            </Card>
          ),
        }));
    }

    return null;
  }, [product, isFullScreen]);

  return (
    <div {...handlers} className={classes.galleryContainer}>
      {images && (
        <>
          {images.length > 0 ? (
            <>
              {images.length === 1 ? (
                <Card
                  className={`${classes.imgContainer} ${
                    isFullScreen ? classes.imgContainerFull : classes.imgContainerSmall
                  }`}
                  style={{ height: 700 }}
                >
                  <img
                    alt=''
                    className={classes.imgOne}
                    style={{ objectFit: 'contain' }}
                    src={images[0].original}
                  />
                </Card>
              ) : (
                <ImageGalleryLib
                  onScreenChange={boolean => setIsFullScreen(boolean)}
                  items={images}
                  useBrowserFullscreen
                  showThumbnails={images.length > 1}
                  showFullscreenButton={false}
                  disableThumbnailScroll={false}
                  showPlayButton={false}
                  thumbnailPosition='left'
                  infinite
                  renderLeftNav={onClick => (
                    <IconButton
                      onClick={onClick}
                      className={classes.arrow}
                      style={{ left: 15 }}
                    >
                      <ArrowBack style={{ fontSize: 30, color: '#216214' }} />
                    </IconButton>
                  )}
                  renderRightNav={onClick => (
                    <IconButton
                      onClick={onClick}
                      className={classes.arrow}
                      style={{ right: 15 }}
                    >
                      <ArrowNext style={{ fontSize: 30, color: '#216214' }} />
                    </IconButton>
                  )}
                />
              )}
            </>
          ) : (
            <Card
              className={`${classes.imgContainer} ${classes.imgContainerSmall}`}
              elevation={0}
            >
              <img
                alt=''
                className={classes.img}
                src={toAbsoluteUrl('/images/placeholder.png')}
              />
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default ImageGalleryNoWine;
