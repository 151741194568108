import axios from 'axios';
import {
  addProuctToCartUrl,
  checkoutCartUrl,
  clearCartUrl,
  createCartUrl,
  deleteCartUrl,
  editCartUrl,
  getCartUrl,
  setProductCountUrl,
} from '../constants';
import { ICartCheckout } from '../pages/home/cart/interfaces';

export function setProductCount({
  hash,
  product_id,
  count,
}: {
  hash: string;
  product_id: number;
  count: number;
}) {
  return axios.put(setProductCountUrl(hash, product_id, count), {});
}

export function getCart(hash?: string) {
  return axios.get(getCartUrl(), { params: { hash } });
}

export function deleteCart(hash: string) {
  return axios.delete(deleteCartUrl(hash));
}

export function clearCart(hash: string) {
  return axios.put(clearCartUrl(hash), {});
}

export function createCart() {
  return axios.post(createCartUrl(), {});
}

export function addProduct({
  product_id,
  count,
  hash,
}: {
  product_id: number;
  count: number;
  hash: string;
}) {
  return axios.post(addProuctToCartUrl(hash, product_id, count), {});
}

export function cartCheckout(data: ICartCheckout, hash: string) {
  return axios.post(checkoutCartUrl(hash), {
    address: data.address,
    billingAddress: data.billing_address,
    lat: data.lat,
    lng: data.lng,
    storeId: data.storeId,
    deliveryDateString: data.deliveryDateString,
    status: 'new',
    name: data.name,
    surname: data.surname,
    comment: data.comment,
    phoneNumbers: data.phoneNumbers,
    countryId: data.countryId,
    email: data.email,
    city: data.city,
    flat: data.flat,
    region: data.region,
    home: data.home,
    street: data.street,
    locationId: data.locationId || 0,
    payType: data.payType,
    locationId2: data.locationId2 || 0,
    deliveryType: data.deliveryType || '',
    deliveryId: data.deliveryId,
  });
}

export function cartEdit(
  name: string,
  surname: string,
  comment: string,
  email: string,
  phoneNumbers: string,
  hash: string,
  deliveryDateString: string,
  countryId?: number
) {
  return axios.put(editCartUrl(hash), {
    name: name || undefined,
    surname: surname || undefined,
    comment: comment || undefined,
    email: email || undefined,
    phoneNumbers,
    countryId,
    deliveryDateString,
  });
}

export function paymentLink(order_id: number, order_hash?: string) {
  return axios.post(`/api/gp/payment_intent/${order_id}`, {}, { params: { order_hash } });
}
