import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { useIntl } from 'react-intl';
import TermDialog from './TermDialog';

interface IAlertDialog extends DialogProps {
  handleAgree: any;
  handleClose: any;
  cancelText?: string;
}

const useStyles = makeStyles((theme) => ({
  cookieText: {
    width: '100%',
    fontFamily: 'MerriweatherSans',
    margin: 0,
    fontSize: 24,
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000',
  },
  cookieTextBold: {
    fontWeight: 'bold',
    color: '#78dc78',
    cursor: 'pointer',
  },
  dialogContent: {
    display: 'flex',
    // width: '326px',
    position: 'fixed',
    borderRadius: 0,
    // right: '0',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 50,
    maxWidth: 650,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
      padding: 30,
    },
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 30,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: 20,
      maxWidth: '80vw',
      marginTop: 30,
      flexDirection: 'column'
    },
  },
  btn: {
    borderRadius: 0,
    width: '33%',
    backgroundColor: '#866999',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  btnContent: {
    padding: 0,
    margin: 0,
    fontSize: 24,
    color: '#fff',
    fontFamily: 'MerriweatherSans',
    lineHeight: 1.13,
    fontWeight: 'normal',
    textTransform: 'none',
  }
}));

const CookiesDialog: React.FC<IAlertDialog> = ({ open, handleClose, handleAgree }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    !open && setOpenDialog(false);
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => handleClose(reason)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        classes={{ paper: classes.dialogContent }}
        disableEscapeKeyDown
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <p
              style={{
                fontSize: 60,
                color: '#000',
                fontWeight: 300,
                marginBottom: 15,
                lineHeight: 1,
                fontFamily: 'MerriweatherSans',
              }}
            >
              {intl.formatMessage({ id: 'COOKIE.TITLE' })}
            </p>
            <p className={classes.cookieText}>{intl.formatMessage({ id: 'COOKIE.TEXT' })}</p>
            <p className={classes.cookieText}>{intl.formatMessage({ id: 'COOKIE.TEXT2' })}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.containerBtn}>
            <Button
              className={classes.btn}
              style={{backgroundColor: '#216214'}}
              fullWidth
              onClick={() => handleAgree('accepted')}
              variant='contained'
              color='primary'
              autoFocus
            >
              <span className={classes.btnContent}>
                {intl.formatMessage({ id: 'COOKIE.BUTTON1' })}
              </span>
            </Button>
            <Button
              className={classes.btn}
              fullWidth
              onClick={() => handleAgree('necessary')}
              variant='contained'
              color='primary'
              autoFocus
            >
              <span className={classes.btnContent}>
                {intl.formatMessage({ id: 'COOKIE.BUTTON2' })}
              </span>
            </Button>
            <Button
              className={classes.btn}
              fullWidth
              onClick={() => handleAgree('rejected')}
              variant='contained'
              color='primary'
              autoFocus
            >
              <span className={classes.btnContent}>
                {intl.formatMessage({ id: 'COOKIE.BUTTON3' })}
              </span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <TermDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        contentType='cookie'
      />
    </>
  );
};

export default CookiesDialog;
