import axios from 'axios';
import {
  LOGIN_URL,
  REGISTER_URL,
  getRecoveryPasswordUrl,
  NEW_PASSWORD_URL,
  sendSmsUrl,
  GET_COUNTRY_ID,
} from '../constants';
import { IUserForRegister, TRole } from '../pages/auth/interfaces';
import { LoginType } from '../interfaces/user';

export function login(loginProp: string, password: string) {
  const formData = new FormData();
  formData.append('login', loginProp);
  formData.append('password', password);
  return axios.post(LOGIN_URL, formData);
}

export const register = (data: IUserForRegister) => {
  let url = REGISTER_URL;

  const newData = data;

  if (data.country_code && data.country_name) {
    url += `?country_code=${data.country_code}&country_name=${data.country_name}`;
    delete newData.country_code;
    delete newData.country_name;
  }

  return axios.post(url, newData);
};

export function recoveryPassword(email: string) {
  return axios.get(getRecoveryPasswordUrl(email));
}

export function newPassword(data: { password: string; password2: string; code: string }) {
  return axios.post(NEW_PASSWORD_URL, data);
}

export const findInSystem = ({ value, type }: { value: string; type: LoginType }) =>
  axios.get(`api/_p/user/find_in_system?${type}=${value}`);

export function loginByPhone(
  phone: string,
  code: string,
  country_code: string,
  email?: string,
  fio?: string
) {
  return axios.post(
    `/api/users/code_login?country_code=${country_code}&phoneNumbers=${phone
      .replace(`+${country_code}`, '')
      .replace(/ /g, '')
      .replace(/[{()}]/g, '')
      .trim()}&code=${code}`,
    null,
    {
      params: {
        email: email || undefined,
        fio: fio || undefined,
      },
    }
  );
}
export const sendCodeConfirm = (phone: string, roles?: string[]) => {
  return axios.post(`api/users/code_register_sms?country_name=Australia&country_code=61`, {
    countryId: 2,
    roles: roles || undefined,
    phoneNumbers: phone
      .replace('+', '')
      .replace(/ /g, '')
      .replace(/[{()}]/g, '')
      .slice(2),
  });
};

export function sendSms(
  phone: string,
  country_name: string,
  country_code: string,
  roles?: TRole[],
  countryId?: number | null,
  email?: string,
  firstName?: string,
  lastName?: string
) {
  return axios.post(
    sendSmsUrl(country_name, country_code),
    {
      roles: roles || undefined,
      countryId: countryId || 2,
      phoneNumbers: phone.replace(/[{()}]/g, ''),
      email: email || undefined,
      firstName,
      lastName,
    },
    {
      params: {
        email,
      },
    }
  );
}

export function getCountryid() {
  return axios.get(GET_COUNTRY_ID);
}
