import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { useSnackbar } from 'notistack';

import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { TAuthTabs } from '.';
import { useFormikEnterConfirmCode } from '../hooks/useFormikEnterConfirmCode';
import { useAxiosPostCallback } from '../../../hooks/useAxiosGet';
import { useShowErrors } from '../../../hooks/useShowErrors';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { ButtonTheme } from '../../../components/ui/Buttons/ButtonWithLoader';
import { sendSms } from '../../../crud/auth.crud';
import Preloader from '../../../components/other/Preloader/Preloader';
import { setStorage } from '../../../../_metronic';

export interface IUserData {
  lastName?: string;
  firstName?: string;
  email?: string;
}

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
  countryData: any;
  phone: string;
  values2: any;
  userData?: IUserData;
  back?: boolean;
  handleClose?: () => void;
  successSend?: boolean;
  setSuccessSend: (value: boolean) => void;
  isCart?: boolean;
}

export const EnterConfirmCode: React.FC<IProps> = ({
  changeAuthTab,
  countryData,
  phone,
  values2,
  userData,
  back,
  handleClose,
  successSend,
  isCart,
  setSuccessSend,
}) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [sms, setSms] = useState(false);
  const [res, loading, err, makeRequest] = useAxiosPostCallback();

  const {
    handleBlur,
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormikEnterConfirmCode(countryData, phone, makeRequest);

  useEffect(() => {
    if (res) {
      values2 &&
        setStorage('questCartForm', JSON.stringify({ ...values2, contactSkip: true }), null);
      dispatch(authActions.loginSuccess(res.data));
    }
  }, [res]);

  useEffect(() => {
    if (isCart) {
      sendSms(
        phone,
        countryData.en_name,
        countryData.code,
        ['ROLE_BUYER'],
        undefined,
        userData?.email,
        userData?.firstName,
        userData?.lastName
      )
        .then(() => setSms(true))
        .catch(e => {
          enqueueSnackbar(
            e?.response?.status === 403
              ? intl.formatMessage({ id: 'USER.NO.BUYER' })
              : e?.response?.data?.message || 'Network error',
            { variant: 'error' }
          );
          handleClose && handleClose();
        });
    } else if (successSend) {
      setSms(true);
    } else {
      sendSms(
        phone,
        countryData.en_name,
        countryData.code,
        ['ROLE_BUYER'],
        undefined,
        userData?.email,
        userData?.firstName,
        userData?.lastName
      )
        .then(() => setSms(true))
        .catch(e => {
          enqueueSnackbar(e?.response?.data?.message || 'Network error', { variant: 'error' });
          handleClose && handleClose();
        });
    }
    // if (successSend) {
    //   setSms(true);
    // } else {
    //   sendSms(
    //     phone,
    //     countryData.en_name,
    //     countryData.code,
    //     ['ROLE_BUYER'],
    //     undefined,
    //     userData?.email,
    //     userData?.firstName,
    //     userData?.lastName
    //   )
    //     .then(() => setSms(true))
    //     .catch(e => {
    //       enqueueSnackbar(e?.response?.data?.message || 'Network error', { variant: 'error' });
    //       handleClose && handleClose();
    //     });
    // }
  }, []);

  useShowErrors([err]);

  if (!sms) return <Preloader />;

  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>{intl.formatMessage({ id: 'ENTER_CONFIRM_CODE' })}</h3>
        </div>

        <form noValidate autoComplete='off' className='kt-form' onSubmit={handleSubmit}>
          <div className='form-group'>
            <div className='form-group'>
              <InputMask
                mask='9999'
                onBlur={handleBlur}
                value={values.code}
                maskPlaceholder={null}
                alwaysShowMask={false}
                onChange={(e: any) => {
                  setFieldValue('code', e.target.value || '');
                }}
              >
                {(inputProps: any) => (
                  <TextField
                    {...inputProps}
                    type='tel'
                    label={intl.formatMessage({
                      id: 'AUTH.INPUT.CODE',
                    })}
                    margin='normal'
                    className='kt-width-full'
                    name='code'
                    helperText={touched.code && errors.code}
                    error={Boolean(touched.code && errors.code)}
                    InputLabelProps={{
                      shrink: !!values.code,
                    }}
                    InputProps={{
                      inputMode: 'numeric',
                    }}
                  />
                )}
              </InputMask>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {back && (
              <div className='kt-login__actions' style={{ marginRight: 30 }}>
                <ButtonWithLoader
                  onPress={() => {
                    changeAuthTab('CheckInSystem');
                    setSuccessSend(false);
                  }}
                  margin
                >
                  {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
                </ButtonWithLoader>
              </div>
            )}

            <div className='kt-login__actions' style={{ width: !back ? '100%' : '' }}>
              <ButtonWithLoader
                style={{
                  width: !back ? '100%' : '',
                  padding: !back ? 0 : '',
                  marginRight: !back ? 0 : '',
                }}
                theme={ButtonTheme.SECONDARY}
                onPress={handleSubmit}
                disabled={loading}
                loading={loading}
              >
                <FormattedMessage id='AUTH.LOGIN.BUTTON' />
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
