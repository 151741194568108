import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

// import '@formatjs/intl-relativetimeformat/polyfill';
// import '@formatjs/intl-relativetimeformat/locale-data/en';

import enMessages from './messages/en';
import ruMessages from './messages/ru';
import czMessages from './messages/cz';
import deMessages from './messages/de';

const allMessages = {
  en: enMessages,
  // ru: ruMessages,
  cz: czMessages,
  de: deMessages
};

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  // const locale = 'en'
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
